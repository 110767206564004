import { useFormik } from 'formik';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import http from 'services/httpService';
import IconClose from 'utils/icons/IconClose';
const ModalTransferGem = (props) => {
  const userAuth = useAuth();
  const setAuth = useAuthAction();

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const toastVariables = {
    hideProgressBar: false,
    autoClose: 3000,
    isLoading: false,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      amount: '',
    },
    onSubmit: (values) => {
      setDisabledSubmitBtn(true);
      const id = toast.loading('please wait ...');
      http
        .post(
          '/transactions/send',
          {
            to: values.username,
            amount: Number(values.amount),
          },
          {
            headers: { Authorization: userAuth.token },
          },
        )
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          if (!error.response) {
            toast.update(id, {
              render: 'please check network connection ...',
              type: 'error',
              ...toastVariables,
            });
          } else if (error.response.data.message && error.response.data.status !== 500) {
            toast.update(id, {
              render: error.response.data.message,
              type: 'error',
              ...toastVariables,
            });
          } else {
            toast.dismiss();
          }
        })
        .finally(() => {
          setTimeout(() => {
            setDisabledSubmitBtn(false);
          }, 5000);
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.username) {
        errors.username = 'Username is required !';
      } else if (
        !String(values.username)
          .toLowerCase()
          .match(/^[A-Za-z][A-Za-z0-9]*$/)
      ) {
        errors.username = 'Please type User Name in english and without space!';
      } else if (values.username.length < 5) {
        errors.username = 'username must be more than 5 charater!';
      } else if (!Number(values.amount)) {
        errors.amount = 'Amount is required !';
      } else if (!/^\d+\.\d{3}$/.test(values.amount)) {
        errors.amount = 'Amount is not valid! | simple: 1.240';
      } else if (Number(userAuth.gems) < Number(values.amount)) {
        errors.amount = 'You dont have enough gem!';
      }
      return errors;
    },
  });

  useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  return (
    <div>
      <div
        className={`modalOverlay ${
          props.isShow ? 'visible bg-black/30' : 'invisible opacity-0'
        }`}
      />
      <div
        className={`modalAnimationShowAndHide ${
          props.isShow
            ? 'scale-1 !z-9999 visible opacity-100'
            : 'invisible !-z-10 scale-75 opacity-0'
        }`}
      >
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex w-full items-center justify-center`}
        >
          <div
            onClick={() => {
              props.closeHandler(false);
              formik.resetForm();
              toast.dismiss();
            }}
            className={`fixed bottom-0 left-0 right-0 top-0`}
          />
          <div
            className={`relative z-20 flex w-[90%] flex-col items-end justify-between gap-4 rounded-[25px] bg-white p-4 transition-all duration-300 md:w-[550px]`}
          >
            <div className="flex w-full items-start justify-between">
              <div className="my-4 flex w-full flex-col gap-3">
                <p className="text-[20px] font-bold">Transfer Gem</p>
              </div>
              <button
                className={`rounded-full p-2.5 transition-all duration-500 hover:bg-gray-100 ${
                  disabledSubmitBtn ? 'invisible' : 'visible'
                }`}
                onClick={() => {
                  props.closeHandler(false);
                  formik.resetForm();
                  toast.dismiss();
                }}
              >
                <IconClose />
              </button>
            </div>
            <div className="w-full space-y-6">
              <div>
                <form
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  className="flex flex-col items-center gap-8"
                >
                  <div className="form-control w-full">
                    <label className="ml-1 font-semibold text-[#9a9a9a] text-[14]">
                      To Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      {...formik.getFieldProps('username')}
                      className={`mt-2 h-12 w-full rounded-lg border pb-1 pl-2 pt-1 text-[17px] font-medium placeholder:text-[#d9d9d9] focus-within:border-[#7b6ffa] focus-within:shadow-md focus:outline-none ${
                        formik.errors.username && formik.touched.username
                          ? 'mb-2 border-[#FF5858]'
                          : 'border-[#ECECEC]'
                      }`}
                      autoComplete="off"
                      spellCheck={false}
                    />
                    {formik.errors.username && formik.touched.username && (
                      <span className="pl-1 text-[13px] font-medium text-[#FF5858]">
                        {formik.errors.username}
                      </span>
                    )}
                  </div>
                  <div className="form-control w-full">
                    <label className="ml-1 font-semibold text-[#9a9a9a] text-[14]">
                      Amount
                    </label>
                    <input
                      type="text"
                      name="amount"
                      value={formik.values.amount}
                      className={`mt-2 h-12 w-full rounded-lg border pb-1 pl-2 pt-1 text-[17px] font-medium placeholder:text-[#d9d9d9] focus-within:border-[#7b6ffa] focus-within:shadow-md focus:outline-none ${
                        formik.errors.amount && formik.touched.amount
                          ? 'mb-2 border-[#FF5858]'
                          : 'border-[#ECECEC]'
                      }`}
                      autoComplete="off"
                      spellCheck={false}
                      onChange={(e) => {
                        const value = e.target.value;
                        const reg = /^[0-9]*\.?[0-9]*$/;
                        if (value === '' || reg.test(value)) {
                          formik.setFieldValue('amount', value);
                        }
                      }}
                    />
                    {formik.errors.amount && formik.touched.amount && (
                      <span className="pl-1 text-[13px] font-medium text-[#FF5858]">
                        {formik.errors.amount}
                      </span>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={disabledSubmitBtn}
                    className={`w-full rounded-lg border py-3.5 text-[18px] font-semibold text-white transition-all duration-500 focus:outline-none ${
                      Object.keys(formik.errors).length === 0 &&
                      !disabledSubmitBtn &&
                      Object.values(formik.values).some((v) => v)
                        ? 'border-[#5142ff] bg-[#5142ff] hover:bg-[#5143ea]'
                        : 'bg-[#D4D4D4]'
                    }`}
                  >
                    Transfer Gem
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTransferGem;
