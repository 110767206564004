import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import AdsenseTop from 'components/website/adsense/top';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';

const GameMusicSongInformation = ({
  cover,
  musicName,
  artistName,
  spotifyLink,
  youtubeLink,
  playGameHandler,
  questionNumber,
  getQuestionResultHandler,
}) => {
  const width = useInnerWidth();
  return (
    <div className="container mb-8 mt-6 grid h-full w-full grid-cols-1 md:grid-cols-5 xl:px-24">
      <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
        {width > 768 && <AdsenseLeft />}
      </div>
      <div className="col-span-3 flex h-full w-full flex-col items-center justify-center gap-2 md:py-20">
        <div className="w-full rounded-xl">{<AdsenseTop />}</div>
        <div className="flex flex-col items-center gap-8 rounded-2xl border-2 bg-[#edecec] p-3 sm:p-6">
          <div className="h-[200px] w-[200px]">
            <img src={cover} className="h-full w-full rounded-[24px]" alt="" />
          </div>
          <div className="space-y-1.5 text-center">
            <p className="text-[20px] font-bold">{musicName}</p>
            <p className="text-[15px] font-medium text-[#7E7E7E]">
              {artistName}
            </p>
          </div>
          {(spotifyLink || youtubeLink) && (
            <div className="flex gap-4">
              {spotifyLink && (
                <a
                  href={spotifyLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex h-[45px] w-[135px] items-center justify-center gap-2 rounded-lg border border-black font-semibold"
                >
                  <img alt="" src={IMAGES.website.routes.games.song.spotify} />
                  <span>Spotify</span>
                </a>
              )}
              {youtubeLink && (
                <a
                  href={youtubeLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex h-[45px] w-[135px] items-center justify-center gap-2 rounded-lg border border-black font-semibold transition-all duration-500"
                >
                  <img alt="" src={IMAGES.website.routes.games.song.youtube} />
                  <span>Youtube</span>
                </a>
              )}
            </div>
          )}
          <button
            className="h-[55px] w-[300px] rounded-lg bg-[#5142FF] text-[16px] font-semibold text-white transition-all duration-500 hover:bg-[#4231f7] md:w-[340px]"
            onClick={() => {
              questionNumber === 3 && getQuestionResultHandler();
              playGameHandler();
            }}
          >
            {questionNumber === 3 ? 'Get Result' : 'Next Question'}
          </button>
        </div>
        <div className="w-full rounded-xl">
          <AdsenseBottom />
        </div>
      </div>
      <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
        {width > 768 && <AdsenseRight />}
      </div>
    </div>
  );
};

export default GameMusicSongInformation;
