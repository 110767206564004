import IMAGES from 'constants/images';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import { useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import SecureLS from 'secure-ls';
import http from 'services/httpService';

const GameImageAnswer = ({
  image,
  questions,
  questionToken,
  reRenderPlayHandler,
}) => {
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const [showSpinerLoading, setShowSpinerLoading] = useState(true);
  const [pauseCircleTimer, setPauseCircleTimer] = useState(false);
  const [hidenSpinerLoaderAndShowImg, setHidenSpinerLoaderAndShowImg] =
    useState(null);
  const userAuth = useAuth();
  const questionAnswersRef = useRef();
  const imageRef = useRef();
  const currectQuestionIndexRef = useRef(null);
  const disableQuestionIndexRef = useRef(null);
  const [key, setKey] = useState(0);
  const setAuth = useAuthAction();
  const [imageSrc, setImageSrc] = useState(image);
  const [questionNumber, setQuestionNumber] = useState(null);
  const [isShowNextQuestionBtn, setIsShowNextQuestionBtn] = useState(false);
  const [currentGameStepPoint, setCurrentGameStepPoint] = useState(0);

  const showQuestionAnswerHandler = (event, fetchApi) => {
    setPauseCircleTimer(true);
    questionAnswersRef.current.classList.add('pointer-events-none');
    event &&
      questionAnswersRef.current.children[
        event.target.getAttribute('button-key')
      ].classList.add('!border-[#5142FF]');
    let selectedAnswer = !event ? '!' : event.target.innerText;
    setHidenSpinerLoaderAndShowImg(false);
    const answers = questionAnswersRef.current.children;
    http
      .post(
        `/questions/photo/${fetchApi}/`,
        fetchApi === 'check'
          ? { token: questionToken, key: selectedAnswer }
          : { token: questionToken },
        { headers: { Authorization: userAuth.token } },
      )
      .then(({ data }) => {
        //* set game point for show to user
        setCurrentGameStepPoint((prev) => prev + data.thisGamePoint);
        //* update user-point
        if (data.userPoints) {
          const userData = JSON.parse(secureLocalStorage.get('userAuth'));
          userData.points = data.userPoints;
          setAuth(userData);
        }
        setQuestionNumber(data.questionNumber);
        setImageSrc(data.photo);
        for (let i = 0; i < answers.length; i++) {
          if (answers[i].outerText === data.key) {
            currectQuestionIndexRef.current =
              answers[i].getAttribute('button-key');
          }
          if (data.delete) {
            if (answers[i].outerText === data.delete) {
              disableQuestionIndexRef.current =
                answers[i].getAttribute('button-key');
              questionAnswersRef.current.children[
                disableQuestionIndexRef.current
              ].classList.add('!bg-[#E6E6E6]');
              questionAnswersRef.current.children[
                disableQuestionIndexRef.current
              ].classList.add('text-[#939393]');
              questionAnswersRef.current.children[
                disableQuestionIndexRef.current
              ].classList.add('!pointer-events-none');
            }
          }
        }
        if (
          data.message === 'try one more time!' ||
          data.message === 'Your answer was not correct!'
        ) {
          setHidenSpinerLoaderAndShowImg('failed');
          event &&
            questionAnswersRef.current.children[
              event.target.getAttribute('button-key')
            ].classList.add('!bg-red-400');
          event &&
            questionAnswersRef.current.children[
              event.target.getAttribute('button-key')
            ].classList.add('!border-transparent');
          event &&
            questionAnswersRef.current.children[
              event.target.getAttribute('button-key')
            ].classList.add('!text-white');
          if (data.message !== 'Your answer was not correct!') {
            setTimeout(() => {
              if (event) {
                questionAnswersRef.current.children[
                  event.target.getAttribute('button-key')
                ].classList.remove('!bg-red-400');
                questionAnswersRef.current.children[
                  event.target.getAttribute('button-key')
                ].classList.add('!bg-gray-200');
              }
              event &&
                questionAnswersRef.current.children[
                  event.target.getAttribute('button-key')
                ].classList.add('pointer-events-none');
              setHidenSpinerLoaderAndShowImg('x');
              setKey((prevKey) => prevKey + 1);
              setShowSpinerLoading(false);
              setHidenSpinerLoaderAndShowImg(false);
              setPauseCircleTimer(false);
            }, 1500);
          }
          if (data.message === 'Your answer was not correct!') {
            questionAnswersRef.current.children[
              currectQuestionIndexRef.current
            ]?.classList.add('!bg-green-400');
            setTimeout(() => {
              setIsShowNextQuestionBtn(true);
              setHidenSpinerLoaderAndShowImg('point');
            }, 2000);
          }
          setTimeout(() => {
            questionAnswersRef.current.classList.remove('pointer-events-none');
          }, 2000);
        } else if (data.message === 'Your answer was correct!') {
          event &&
            questionAnswersRef.current.children[
              event.target.getAttribute('button-key')
            ].classList.add('!bg-green-400');
          event &&
            questionAnswersRef.current.children[
              event.target.getAttribute('button-key')
            ].classList.add('!border-transparent');
          setHidenSpinerLoaderAndShowImg('success');
          setTimeout(() => {
            setIsShowNextQuestionBtn(true);
            setHidenSpinerLoaderAndShowImg('point');
          }, 3000);
        } else if (data.message === 'Your time has been finished!') {
          setHidenSpinerLoaderAndShowImg('failed');
          for (let i = 0; i < answers.length; i++) {
            if (answers[i].outerText === data.key) {
              currectQuestionIndexRef.current =
                answers[i].getAttribute('button-key');
              questionAnswersRef.current.children[
                currectQuestionIndexRef.current
              ].classList.add('!bg-green-400');
            }
          }
          setTimeout(() => {
            setIsShowNextQuestionBtn(true);
            setHidenSpinerLoaderAndShowImg('point');
          }, 3000);
        }
      })
      .catch((error) => {
        toast.dismiss();
      });
  };

  return (
    <>
      <div className="relative flex w-full flex-col items-center">
        <div className="absolute -top-[65px] right-0 flex h-[60px] w-[60px] items-center justify-center">
          <div
            className={`countDownCircleTimer absolute bottom-0 left-0 right-0 top-0 text-[20px] font-black text-[#5142FF] ${
              showSpinerLoading ||
              hidenSpinerLoaderAndShowImg ||
              pauseCircleTimer
                ? 'hidden'
                : 'block'
            }`}
          >
            <CountdownCircleTimer
              isPlaying={pauseCircleTimer || showSpinerLoading ? false : true}
              duration={10}
              key={key}
              colors={'#5142FF'}
              size={56}
              strokeWidth={5}
              trailColor={'#d8d8d8'}
              rotation={'counterclockwise'}
              onComplete={() => {
                showQuestionAnswerHandler(null, 'timeout');
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>
          <img
            className={`absolute left-0 right-0 top-0 transition-all duration-500 ${
              hidenSpinerLoaderAndShowImg === 'success' ? 'block' : 'hidden'
            }`}
            src={IMAGES.website.routes.games.global.success}
            width={60}
            alt=""
          />
          <img
            className={`absolute left-0 right-0 top-0 transition-all duration-500 ${
              hidenSpinerLoaderAndShowImg === 'failed' ? 'block' : 'hidden'
            }`}
            src={IMAGES.website.routes.global.failed}
            width={60}
            alt=""
          />
          <div
            className={`$ absolute left-0 right-0 top-0 flex h-[55px] w-[55px] items-center 
              justify-center rounded-full border-[5px] text-[20px] font-black ${
                hidenSpinerLoaderAndShowImg === 'point' ? 'block' : 'hidden'
              }
              ${
                currentGameStepPoint === 0
                  ? 'border-yellow-400 text-yellow-400'
                  : currentGameStepPoint < 0
                  ? 'border-red-400 text-red-400'
                  : currentGameStepPoint > 0
                  ? 'border-green-500 text-green-500'
                  : ''
              }`}
          >
            {Number(currentGameStepPoint) > 0 && '+'}
            {currentGameStepPoint}
          </div>
          <div
            className={
              (showSpinerLoading && !hidenSpinerLoaderAndShowImg) ||
              (!hidenSpinerLoaderAndShowImg && pauseCircleTimer)
                ? `absolute bottom-0 left-0 right-0 top-0 block`
                : 'hidden'
            }
          >
            <Oval
              height={57}
              width={57}
              color="#5142FF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#777"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <div className="h-[200px] w-[300px] overflow-hidden rounded-[28px] bg-white xl:h-[300px] xl:w-[450px]">
              <img
                src={imageSrc}
                ref={imageRef}
                onLoad={() => setShowSpinerLoading(false)}
                className="h-full w-full"
                alt=""
              />
            </div>
          </div>
          <div
            className="mb-2 mt-5 grid grid-cols-2 gap-4"
            ref={questionAnswersRef}
          >
            {questions.map((item, index) => {
              return (
                <button
                  button-key={index}
                  onClick={(e) => showQuestionAnswerHandler(e, 'check')}
                  className={`h-[100px] w-[150px] !rounded-2xl border border-white bg-white text-[16px] font-medium shadow-sm shadow-slate-200 outline-none transition-all duration-300 hover:bg-gray-50 sm:w-[170px] xl:h-[130px] xl:w-full ${
                    showSpinerLoading && 'pointer-events-none'
                  }`}
                  key={index}
                >
                  {item}
                </button>
              );
            })}
          </div>
          {isShowNextQuestionBtn && (
            <div className="flex w-full justify-center">
              <button
                className={`mt-3 w-[300px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-5 pt-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] md:w-[350px]`}
                onClick={() => {
                  reRenderPlayHandler();
                }}
              >
                {questionNumber === 3 ? 'Get Result' : 'Next Question'}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GameImageAnswer;
