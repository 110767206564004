import {
  SkeletonGameMusicCategoryItem,
  SkeletonGameMusicCategoryItemLargeScreen,
} from 'components/global/skeleton';
import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import ModalPleaseLogin from 'components/website/modals/ModalPleaseLogin';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';
import { useAuth } from 'providers/AuthProvider';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import StartGameCountDown from './StartGameCountDown';

const HowToPlay = ({ gameName }) => {
  const navigate = useNavigate();
  const userAuth = useAuth();
  const width = useInnerWidth();
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const localStorageUserAuth = JSON.parse(
    secureLocalStorage.get('userAuth') || false,
  );
  const [componentActive, setComponentActive] = useState('how-to-play');
  const [selectedMusicCategory, setSelectedMusicCategory] = useState(null);
  const [musicCategory, setMusicCategory] = useState(null);

  const [isShowModalPleaseLogin, setIsShowModalPleaseLogin] = useState(false);

  const hiddenPleaseLoginHandler = () => {
    setIsShowModalPleaseLogin(false);
  };

  const [userNotRobot, setUserNotRobot] = useState(() =>
    window.location.hostname === 'localhost' ? true : false,
  );

  useEffect(() => {
    if (localStorageUserAuth) {
      http.get(`/categories/music`).then(({ data }) => {
        setMusicCategory(data);
      });
    }
  }, []);

  return (
    <>
      <ModalPleaseLogin
        closeHandler={hiddenPleaseLoginHandler}
        isShow={isShowModalPleaseLogin}
      />
      {componentActive === 'how-to-play' && (
        <div className="w-full bg-white">
          <div className="flex flex-col items-center px-4 py-8 lg:container lg:flex-row xl:px-16 2xl:px-32">
            <div className="flex flex-col items-center lg:w-[55%] lg:items-start lg:gap-2">
              <p className="py-3 text-[34px] font-black lg:w-[400px] lg:text-[56px]">
                HOW TO PLAY
              </p>
              <div className="px-4 text-center text-[18px] font-medium text-[#828282] sm:w-[550px] lg:px-0 lg:text-start">
                {gameName === 'guess-image' ? (
                  <p>
                    Which of these objects do you think is in the magnified
                    image on screen? Start the game and guess what the image is.
                    <br />
                    I'm not sure if I am right or not, but it looks like a
                    grapefruit. Maybe an orange up against some typeof green
                    plant with flowers ? What's your take on this?
                  </p>
                ) : null}
                {gameName === 'song-magic' ? (
                  <p>
                    Test your skills and knowledge of popular music with this
                    free quiz! You can choose between different categories like
                    Rock, Country & Western or even Children's songs. Listen to
                    each example while answering the questions
                  </p>
                ) : null}
                {gameName === 'lyrics-master' ? (
                  <p>
                    The Gibberish Challenge is an interactive game in which the
                    player needs to guess a word or phrase from amongst random
                    sentences that make no sense at all. To do this, they are
                    shown one nonsense sentence after another and must read them
                    carefully before time runs out.
                  </p>
                ) : null}
              </div>
              <p className="my-2 text-[16px] font-bold text-[#7e7e7e]">
                ARE YOU READY? {userAuth?.username?.toUpperCase()}
              </p>
              <button
                onClick={() => {
                  if (userAuth) {
                    if (gameName === 'guess-image') {
                      setComponentActive('image-game');
                    } else if (gameName === 'song-magic') {
                      setComponentActive('song-game-categories');
                    } else if (setComponentActive('lyrics-game')) {
                      setComponentActive('lyrics-game');
                    }
                  } else {
                    setIsShowModalPleaseLogin(true);
                  }
                }}
                className={`mb-6 mt-2 w-[220px] rounded-lg border border-[#5142ff]  bg-[#5142ff] py-3.5 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] ${
                  !userNotRobot
                    ? 'pointer-events-none opacity-60'
                    : 'pointet-events-auto opacity-100'
                }`}
              >
                Let’s Go
              </button>
              <ReCAPTCHA
                sitekey="6LegddUjAAAAACcnNQ6vLjGxjUsLPviSFwVERoN9"
                onChange={() => setUserNotRobot(true)}
              />
            </div>
            <div className="my-4 w-[340px] sm:w-[400px] lg:mb-8 lg:mt-2 lg:w-[45%]">
              <img
                src={IMAGES.website.routes.games.global.manWinner}
                className="w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      )}
      {componentActive === 'image-game' && navigate('/games/image/timer')}
      {componentActive === 'lyrics-game' && navigate('/games/lyrics/timer')}
      {componentActive === 'song-game' && (
        <StartGameCountDown
          selectedGame={'song-magic'}
          selectedCategory={selectedMusicCategory}
        />
      )}
      {componentActive === 'song-game-categories' && (
        <div className="container mb-8 mt-6 grid h-full w-full grid-cols-1 md:grid-cols-5 xl:px-24">
          <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
            {width > 768 && <AdsenseLeft />}
          </div>
          <div className="col-span-3 flex h-full w-full flex-col items-center">
            <div className="my-4 flex w-[340px] flex-col items-center justify-center rounded-2xl border-2 bg-[#edecec] py-4 sm:w-[430px] md:w-full xl:w-[600px]">
              <p className="my-4 text-[22px] font-bold sm:mb-0 lg:text-[26px]">
                Choose A Genre
              </p>
              <div className="grid grid-cols-2 gap-3 sm:my-6 sm:gap-5">
                {musicCategory &&
                  musicCategory.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          (item.name === 'unknown' || item.isEmpty) && 'hidden'
                        }
                      >
                        <button
                          key={index}
                          className="duration-400 flex h-[165px] w-[150px] flex-col items-center justify-center gap-3 rounded-[20px] bg-white shadow-md shadow-slate-200 transition-all hover:bg-[#5142FF] hover:text-white focus:outline-none sm:w-[160px] lg:w-[250px] lg:rounded-[24px]"
                          onClick={() => {
                            setSelectedMusicCategory(item.name);
                            setComponentActive('song-game');
                          }}
                        >
                          <img src={item.photo} className="h-[65px]" alt="" />
                          <p className=" text-[24px] font-bold">{item.name}</p>
                        </button>
                      </div>
                    );
                  })}
                {!musicCategory &&
                  Array.from({ length: 8 }).map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="lg:hidden">
                          <SkeletonGameMusicCategoryItem />
                        </div>
                        <div className="hidden lg:flex">
                          <SkeletonGameMusicCategoryItemLargeScreen />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="w-full rounded-xl">
              <AdsenseBottom />
            </div>
          </div>
          <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
            {width > 768 && <AdsenseRight />}
          </div>
        </div>
      )}
    </>
  );
};

export default HowToPlay;
