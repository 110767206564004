import {
  SkeletonGameLyrics,
  SkeletonGameLyricsLargeScreen,
} from 'components/global/skeleton';
import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import AdsenseTop from 'components/website/adsense/top';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import { useEffect, useRef, useState } from 'react';
import { createRipples } from 'react-ripples';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import GameLyricsAnswer from './GameLyricsAnswer';

const GameLyricsPlay = () => {
  const width = useInnerWidth();
  const userAuth = useAuth();
  const paginationRef = useRef();
  const setAuth = useAuthAction();
  const [isMounted, setIsMounted] = useState(false);
  const [answerData, setAnswerData] = useState(false);
  const [activePaginationNumber, setActivePaginationNumber] = useState(false);
  const [finishQuestion, setFinishQuestion] = useState(false);
  const [userNeedLogout, setUserNeedLogout] = useState(false);
  const [isLimitedUser, setIsLimitedUser] = useState(null);
  const navigate = useNavigate();

  const RippleBtn = createRipples({
    color: 'rgba(255, 255, 255, .2)',
    during: 1500,
  });

  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const localStorageQuestionPackAnswred = JSON.parse(
    secureLocalStorage.get('questionPackAnswredNumber') || false,
  );

  const localStorageUserAuth = JSON.parse(
    secureLocalStorage.get('userAuth') || false,
  );

  useEffect(() => {
    if (finishQuestion) {
      if (localStorageQuestionPackAnswred < 10) {
        secureLocalStorage.set(
          'questionPackAnswredNumber',
          JSON.stringify(
            parseInt(parseInt(localStorageQuestionPackAnswred) + 1),
          ),
        );
      } else {
        setUserNeedLogout(true);
      }
    }
  }, [finishQuestion]);

  const reRenderPlayHandler = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (localStorageUserAuth) {
      if (isMounted) {
        http
          .get('/questions/text', {
            headers: { Authorization: userAuth.token },
          })
          .then(({ data }) => {
            //* if has newQuestion backend-reslut for load questionLyricsGame
            if (data?.question?.message === 'New question :') {
              //* question-descripton (lyrics text) and four-answers and question-token and set state to answerData and load question-lyrics-game
              // show question with daily 2 second
              setTimeout(() => {
                setAnswerData([
                  data.question.description,
                  data.question.answers,
                  data.token,
                ]);
              }, 2000);
              //* set question-number for pagination-bottom
              setActivePaginationNumber(parseInt(data.question.questionNumber));
            } else if (data?.message === 'You answered three questions!') {
              //* if finish tree question show tree-qustion-result
              setFinishQuestion(String(data.questionsPoints));
              //* if isLimited is true show text 'Note: You reached your hourly limit.'
              setIsLimitedUser(data.isLimited);
            }
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'No more questions available to answer!'
            ) {
              toast.error('you have answered all the questions!');
            }
          });
      }
      setIsMounted(true);
    } else {
      navigate('/');
    }
  }, [isMounted]);

  useEffect(() => {
    if (answerData && !finishQuestion) {
      let paginations = [];
      const activePagination =
        paginationRef.current.children[activePaginationNumber];

      paginations.push(...paginationRef.current.children);
      paginations.forEach((i) => {
        i.classList.remove('!bg-[#5142FF]');
      });

      activePagination.classList.add('!bg-[#5142FF]');
    }
  }, [activePaginationNumber, answerData]);

  return (
    <>
      {/* lyrics question box base */}
      <section>
        {/* if bahcked result is question */}
        {userAuth && !finishQuestion && (
          <div className="container mb-8 mt-6 grid h-full grid-cols-1 md:grid-cols-5 xl:px-24">
            <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
              {width > 768 && <AdsenseLeft />}
            </div>
            <div className="col-span-3 flex h-full flex-col items-center justify-between">
              <div className="block w-full rounded-lg">
                <AdsenseTop />
              </div>
              <div className="my-8 min-h-[550px] w-[340px] rounded-2xl border-2 bg-[#edecec] px-6 pt-4 sm:pt-6 md:min-h-[550px] md:w-[400px] xl:min-h-[610px] xl:w-[520px]">
                <p className="mt-4 text-center text-[18px] font-semibold">
                  Gibberish Challenge
                </p>
                <div>
                  {/* fetched question */}
                  {answerData && (
                    <div className="my-6">
                      <GameLyricsAnswer
                        reRenderPlayHandler={reRenderPlayHandler}
                        activePaginationNumber={activePaginationNumber}
                        text={answerData[0]}
                        questions={answerData[1]}
                        questionToken={answerData[2]}
                      />
                    </div>
                  )}
                  {/* show loading before fetch question */}
                  {!answerData && (
                    <>
                      <div className="mt-1 xl:hidden">
                        <SkeletonGameLyrics />
                      </div>
                      <div className="mb-6 mt-4 hidden xl:flex">
                        <SkeletonGameLyricsLargeScreen />
                      </div>
                    </>
                  )}
                  {/* bottom-pagination */}
                  <div className="flex justify-center">
                    <div
                      ref={paginationRef}
                      className="mb-4 flex justify-center gap-2 rounded-full bg-white p-2"
                    >
                      <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                      <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                      <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full rounded-xl">
                <AdsenseBottom />
              </div>
            </div>
            <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
              {width > 768 && <AdsenseRight />}
            </div>
          </div>
        )}
        {/* if backend response is result */}
        {userAuth && finishQuestion && (
          <div className="container mb-8 mt-6 grid h-full grid-cols-1 md:grid-cols-5 xl:px-24">
            <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
              {width > 768 && <AdsenseLeft />}
            </div>
            <div className="col-span-3 flex h-full flex-col items-center">
              <div className="block w-full rounded-lg">
                <AdsenseTop />
              </div>
              <div className="my-6 flex flex-col items-center justify-center gap-5 rounded-2xl border-2 bg-[#edecec] p-8">
                <>
                  {!(Math.sign(parseInt(finishQuestion)) === -1) &&
                    parseInt(finishQuestion) === 0 && (
                      <>
                        <img
                          src={
                            IMAGES.website.routes.games.global.totalEqualPoints
                          }
                          className={'w-[120px]'}
                          alt=""
                        />
                        <p className="text-[34px] font-bold md:text-[44px]">
                          Congrats!
                        </p>
                        <p className="w-[250px] text-center font-medium text-[#828282] md:w-[280px]">
                          you did not earn any points, Let's collect more
                          points!
                        </p>
                      </>
                    )}
                  {!(Math.sign(parseInt(finishQuestion)) === -1) &&
                    parseInt(finishQuestion) !== 0 && (
                      <>
                        <img
                          src={
                            IMAGES.website.routes.games.global
                              .totalPositiveOrNegativePoints
                          }
                          className={'w-[120px]'}
                          alt=""
                        />
                        <p className="text-[34px] font-bold md:text-[44px]">
                          Congrats!
                        </p>
                        <p className="w-[250px] text-center font-medium text-[#828282] md:w-[280px]">
                          You earned {Math.abs(parseInt(finishQuestion))}{' '}
                          points, Let’s collect more points!
                        </p>
                      </>
                    )}
                  {!(Math.sign(parseInt(finishQuestion)) !== -1) &&
                    parseInt(finishQuestion) !== 0 && (
                      <>
                        <img
                          src={
                            IMAGES.website.routes.games.global
                              .totalPositiveOrNegativePoints
                          }
                          className={'w-[120px]'}
                          alt=""
                        />
                        <p className="text-[34px] font-bold md:text-[44px]">
                          Sorry!
                        </p>
                        <p className="w-[250px] text-center font-medium text-[#828282] md:w-[280px]">
                          You Lost {Math.abs(parseInt(finishQuestion))} Points!
                        </p>
                      </>
                    )}
                </>
                {isLimitedUser && (
                  <p className="text-[17px] font-medium text-orange-600">
                    Note: You reached your hourly limit.
                  </p>
                )}
                <RippleBtn className="mb-2 mt-4 w-full">
                  <button
                    className={`w-full rounded-lg border border-[#5142ff] bg-[#5142ff] py-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea]`}
                    onClick={() => {
                      if (userNeedLogout) {
                        localStorage.removeItem('userAuth');
                        localStorage.removeItem('questionPackAnswredNumber');
                        toast.warn(' Please log in again', { autoClose: 3000 });
                        setTimeout(() => {
                          setAuth('');
                          window.open('/', '_self');
                        }, 5000);
                      } else {
                        window.open('/games/lyrics/play', '_self');
                      }
                    }}
                  >
                    Continue Quiz
                  </button>
                </RippleBtn>
              </div>
              <div className="w-full rounded-xl">
                <AdsenseBottom />
              </div>
            </div>
            <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
              {width > 768 && <AdsenseRight />}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default GameLyricsPlay;
