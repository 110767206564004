const IMAGES = {
  global: {
    logo: require('./global/logo.png'),
  },
  dashboard: {
    emptyResult: require('./dashboard/empty-result.png'),
    questionMark: require('./dashboard/question-mark.png'),
  },
  website: {
    global: {
      ghofly3d: require('./website/global/ghofly-3d.png'),
      bee: require('./website/global/bee.gif'),
      gemIcon: require('./website/global/gem-icon.png'),
      pointIcon: require('./website/global/point-icon.png'),
    },
    template: {
      header: {
        mobileMenuCloseBtn:
          require('./website/template/header/mobile-menu-close-btn.svg')
            .default,
        mobileMenuShowBtn:
          require('./website/template/header/mobile-menu-show-btn.svg').default,
      },
    },
    routes: {
      global: {
        failed: require('./website/routes/global/failed.png'),
        mail: require('./website/routes/global/mail.png'),
      },
      home: {
        dotRed: require('./website/routes/home/dot-red.svg').default,
        shapeDots: require('./website/routes/home/shape-dots.svg').default,
        manBallInHand: require('./website/routes/home/man-ball-in-hand.png'),
        manMoney: require('./website/routes/home/man-money.png'),
        manRocket: require('./website/routes/home/man-rocket.png'),
        manShop: require('./website/routes/home/man-shop.png'),
        manSuprised: require('./website/routes/home/man-surprised.png'),
        monitorInCircle: require('./website/routes/home/monitor-in-circle.png'),
        phoneInCircle: require('./website/routes/home/phone-in-circle.png'),
        ps5InCircle: require('./website/routes/home/ps5-in-circle.png'),
        storeWithHand: require('./website/routes/home/store-with-hand.png'),
        plus: require('./website/routes/home/plus.png'),
        threeStar: require('./website/routes/home/three-star.png'),
      },
      leaderBoard: {
        one: require('./website/routes/leader-board/one.png'),
        two: require('./website/routes/leader-board/two.png'),
        tree: require('./website/routes/leader-board/tree.png'),
      },
      resetPassword: {
        lockWithStar: require('./website/routes/reset-password/lock-with-star.png'),
      },
      store: {
        prize: require('./website/routes/store/prize.png'),
      },
      games: {
        current: {
          lyrics: require('./website/routes/games/current/lyrics.png'),
          photo: require('./website/routes/games/current/photo.png'),
          song: require('./website/routes/games/current/song.png'),
          task: require('./website/routes/games/current/task.png'),
        },
        global: {
          success: require('./website/routes/games/global/success.png'),
          manWinner: require('./website/routes/games/global/man-winner.png'),
          totalEqualPoints: require('./website/routes/games/global/total-equal-points.png'),
          totalPositiveOrNegativePoints: require('./website/routes/games/global/total-positive-or-negative-points.png'),
        },
        song: {
          spotify: require('./website/routes/games/song/spotify.svg').default,
          youtube: require('./website/routes/games/song/youtube.svg').default,
          playMusicButton: require('./website/routes/games/song/play-music-button.png'),
        },
        task: {
          gem: require('./website/routes/games/task/gem.png'),
        },
      },
    },
  },
};

export default IMAGES;
