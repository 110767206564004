import { SkeletonCircle, SkeletonObject } from 'components/global/skeleton';
import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import AdsenseTop from 'components/website/adsense/top';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';
import { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import IconPhoto from 'utils/icons/IconPhoto';
const GameTaskPlay = () => {
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const userAuth = JSON.parse(secureLocalStorage.get('userAuth') || false);
  const [taskData, setTaskData] = useState(null);
  const [isShowNextTaskBtn, setIsShowNextTaskBtn] = useState(false);
  const navigate = useNavigate();
  const width = useInnerWidth();
  const toastVariables = {
    hideProgressBar: true,
    autoClose: 1000,
    isLoading: false,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
  };

  // for upload screenshot
  const [selectedScreenShot, setSelectedScreenShot] = useState(null);

  // user not-loggined ? redirect to home page : fetch task
  useEffect(() => {
    if (!userAuth) {
      navigate('/');
    } else {
      getTaskHandler();
    }
  }, []);

  // copy text to clipboard
  const extractTextWithinDoubleQuotes = (text) => {
    const fixedText = text.replace(/[“”]/g, '"');
    const regex = /"(.*?)"/g;
    const matches = fixedText.match(regex);
    return matches ? matches.map((match) => match.slice(1, -1)) : [];
  };
  const copyTextToClipboardHandler = (text) => {
    const finalText = extractTextWithinDoubleQuotes(text).join(' ');
    if (finalText !== '') {
      navigator.clipboard.writeText(finalText);
      toast.success('copied to clipboard!');
    }
  };

  // call in first render for load task
  const getTaskHandler = () => {
    http
      .get(`/tasks`, {
        headers: { Authorization: userAuth.token },
      })
      .then(({ data }) => {
        setTaskData(data);
      })
      .catch((error) => {
        setTimeout(() => {
          if (error.response.data.message && error.response.status !== 500) {
            toast.error(error.response.data.message);
          } else {
            toast.dismiss();
          }
        }, 1000);
      });
  };

  // upload and next task button
  const [disabledUploadAndNextTaskBtn, setDisabledUploadAndNextTaskBtn] =
    useState(true);
  const uploadAndNextTaskBtnHandler = () => {
    setDisabledUploadAndNextTaskBtn(true);
    const id = toast.loading('please wait ...');
    http
      .get('/tasks/upload-screenshot?name=file.png', {
        headers: {
          Authorization: userAuth.token,
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        let formData = new FormData();
        const screenShotLink = data.fileLink;
        formData.append('file', selectedScreenShot);
        Object.keys(data.formData).forEach((key) =>
          formData.append(key, data.formData[key]),
        );
        http
          .post(data.postURL, formData, {
            headers: {
              Authorization: userAuth.token,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            http
              .post(
                `/tasks/done`,
                {
                  image: screenShotLink,
                  subtaskId: taskData.task._id,
                },
                {
                  headers: {
                    Authorization: userAuth.token,
                  },
                },
              )
              .then(() => {
                toast.update(id, {
                  render:
                    taskData.totalTasks === taskData.userPosition + 1
                      ? 'task list finished!'
                      : 'wait for next task!',
                  type: 'success',
                  ...toastVariables,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              })
              .catch((error) => {
                if (!error.response) {
                  toast.update(id, {
                    render: 'please check network connection ...',
                    type: 'error',
                    ...toastVariables,
                  });
                } else {
                  toast.update(id, {
                    render: 'an unexpected error occurred...',
                    type: 'error',
                    ...toastVariables,
                  });
                }
              });
          })
          .catch((error) => {
            if (!error.response) {
              toast.update(id, {
                render: 'please check network connection ...',
                type: 'error',
                ...toastVariables,
              });
            } else {
              toast.update(id, {
                render: 'an unexpected error occurred...',
                type: 'error',
                ...toastVariables,
              });
            }
          });
      })
      .catch((error) => {
        if (!error.response) {
          toast.update(id, {
            render: 'please check network connection ...',
            type: 'error',
            ...toastVariables,
          });
        } else {
          toast.update(id, {
            render: 'an unexpected error occurred...',
            type: 'error',
            ...toastVariables,
          });
        }
        setTimeout(() => {
          setDisabledUploadAndNextTaskBtn(false);
        }, 2000);
      });
  };

  return (
    <>
      <div className="container mb-8 mt-6 grid h-full grid-cols-1 md:grid-cols-5 xl:px-24">
        <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
          {width > 768 && <AdsenseLeft />}
        </div>
        <div className="col-span-3 flex h-full flex-col items-center justify-between">
          <div className="block w-full rounded-lg">
            <AdsenseTop />
          </div>
          <div className="my-4 flex w-[340px] flex-col items-center gap-4 rounded-2xl border-2 bg-[#edecec] p-4">
            {/* if fetched task ? show task : show skeletion */}
            {!!taskData ? (
              // if taskData.task ? show task : show wait for gem verify
              taskData.task ? (
                <>
                  {/* timer and taskGroup gem */}
                  <div className="flex w-full justify-between">
                    <div className=" relative flex h-[56px] w-[56px] flex-col items-center rounded-full border-[5px] border-blue-500 pt-[10px] text-blue-500">
                      <p className="h-[11px] font-black text-[14px]">
                        {Number(taskData.taskGroupGems).toFixed(3)}
                      </p>
                      <p className="h-[14px] text-[13px] font-bold">gem</p>
                    </div>
                    <div
                      className={`text-[20px] font-black text-[#5142FF] ${
                        isShowNextTaskBtn && 'hidden'
                      }`} // show next link button && hide timer
                    >
                      <CountdownCircleTimer
                        isPlaying={true}
                        duration={taskData.task.timer}
                        colors={'#5142FF'}
                        size={56}
                        strokeWidth={5}
                        trailColor={'#d8d8d8'}
                        rotation={'counterclockwise'}
                        onComplete={() => setIsShowNextTaskBtn(true)}
                      >
                        {({ remainingTime }) => remainingTime}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                  {/* task image and task text */}
                  <div className="flex w-full flex-col items-center gap-4">
                    <img
                      src={taskData.task.image}
                      className="w-[300px] min-h-[200px] rounded-[13px] bg-white"
                      alt=""
                    />
                    <p
                      className="w-full rounded-lg bg-white p-3 text-center font-medium"
                      onClick={() =>
                        copyTextToClipboardHandler(taskData.task.text)
                      }
                    >
                      {taskData.task.text}
                    </p>
                  </div>
                  {/* next task button and upload screen shot field */}
                  {isShowNextTaskBtn && (
                    <>
                      <div className="relative w-full rounded-lg border bg-white p-3">
                        <div>
                          <input
                            className="absolute bottom-0 top-0 z-50 cursor-pointer opacity-0"
                            type="file"
                            accept=".png, .jpg"
                            onChange={(e) => {
                              // add image to state and show preview
                              if (e.target.files[0]) {
                                setSelectedScreenShot(e.target.files[0]);
                                setDisabledUploadAndNextTaskBtn(false);
                              }
                            }}
                          />
                        </div>
                        {selectedScreenShot ? (
                          <div className={`flex justify-center`}>
                            <img
                              className="h-[100px] w-[100px]"
                              src={URL.createObjectURL(selectedScreenShot)}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div className="relative flex h-[100px] w-full flex-col items-center justify-center gap-4">
                            <IconPhoto size={48} />
                            <p className="text-[15px] font-medium text-[#9A9A9A]">
                              Select Screen Shot
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="flex w-full justify-center">
                        {/* selected screenshot && enable next link btn */}
                        <button
                          disabled={disabledUploadAndNextTaskBtn}
                          className={`w-[230px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-3 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] ${
                            !disabledUploadAndNextTaskBtn
                              ? 'opacity-100'
                              : 'opacity-50'
                          }`}
                          onClick={uploadAndNextTaskBtnHandler}
                        >
                          Upload and Next Task
                        </button>
                      </div>
                    </>
                  )}
                  {/* pagination */}
                  <div className="flex justify-center gap-2 rounded-full border bg-[#f8f8f8] p-2">
                    {Array.from({ length: taskData.totalTasks }).map(
                      (item, index) => {
                        return (
                          <div
                            className={`h-2 w-2 rounded-full ${
                              taskData.userPosition === index
                                ? 'bg-[#5142ff]'
                                : 'bg-[#d8d8d8]'
                            }`}
                          />
                        );
                      },
                    )}
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={IMAGES.website.routes.games.task.gem}
                    className={'w-[120px]'}
                    alt=""
                  />
                  <p className="text-[22px] font-bold text-center">
                    Congratulations!
                  </p>
                  <p className="text-[18px] font-semibold text-center">
                    Your task list has been successfully completed, if confirmed{' '}
                    <span className="text-[#5142FF] border-b-[2px] border-[#5142FF] px-1">
                      {Number(taskData.gems).toFixed(3)}
                    </span>{' '}
                    gem will be deposited into your account.
                  </p>
                  <button
                    className={`w-[230px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-3 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea]`}
                    onClick={() => window.location.reload()}
                  >
                    Next Task List
                  </button>
                </>
              )
            ) : (
              <div className="flex w-full flex-col items-center gap-4">
                <div className="flex w-full justify-between">
                  <SkeletonCircle />
                  <SkeletonCircle />
                </div>
                <SkeletonObject width={300} height={300} rounded={13} />
                <SkeletonObject width={300} height={70} rounded={10} />
                <div className="flex justify-center gap-2 rounded-full border bg-[#f8f8f8] p-2">
                  {Array.from({ length: 5 }).map(() => {
                    return (
                      <div className={`h-2 w-2 rounded-full bg-[#d8d8d8]`} />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="w-full rounded-xl">
            <AdsenseBottom />
          </div>
        </div>
        <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
          {width > 768 && <AdsenseRight />}
        </div>
      </div>
    </>
  );
};

export default GameTaskPlay;
