import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import AdsenseTop from 'components/website/adsense/top';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';
import { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useNavigate } from 'react-router-dom';
import GameMusicPlay from '../gameMusic/GameMusicPlay';

const StartGameCountDown = ({ selectedGame, selectedCategory }) => {
  const [game, setGame] = useState('loading');
  const width = useInnerWidth();
  const navigate = useNavigate();

  return (
    <>
      {game === 'loading' && (
        <div className="container mb-8 mt-6 grid h-full w-full grid-cols-1 md:grid-cols-5 xl:px-24">
          <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
            {width > 768 && <AdsenseLeft />}
          </div>
          <div className="col-span-3 flex h-full w-full flex-col items-center">
            <div className="w-full rounded-xl">
              <AdsenseTop />
            </div>
            <div className="my-6 flex h-full w-[300px] flex-col items-center justify-center gap-12 rounded-2xl border-2 bg-[#edecec] py-6 sm:w-[400px]">
              <div className="flex w-full flex-col items-center py-4 text-[55px] font-black text-[#FF7658]">
                <CountdownCircleTimer
                  isPlaying={true}
                  duration={10}
                  colors={'#FF7658'}
                  size={125}
                  strokeWidth={10}
                  trailColor={'#ddd'}
                  rotation={'counterclockwise'}
                  onComplete={() => {
                    selectedGame === 'lyrics-master' &&
                      setGame('lyrics-master');
                    selectedGame === 'guess-image' && setGame('guess-image');
                    selectedGame === 'song-magic' && setGame('song-magic');
                    selectedGame === 'task-group' && setGame('task-group');
                  }}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>
              <p className="text-[20px] font-semibold">Game is Starting ...</p>
              <img
                src={IMAGES.website.global.ghofly3d}
                className="h-[60px]"
                alt=""
              />
            </div>
            <div className="w-full rounded-xl">
              <AdsenseBottom />
            </div>
          </div>
          <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
            {width > 768 && <AdsenseRight />}
          </div>
        </div>
      )}
      {game === 'lyrics-master' && navigate('/games/lyrics/play')}
      {game === 'guess-image' && navigate('/games/image/play')}
      {game === 'song-magic' && (
        <GameMusicPlay selectedCategory={selectedCategory} />
      )}
      {game === 'task-group' && navigate('/games/task/play')}
    </>
  );
};

export default StartGameCountDown;
