import { useEffect, useState } from 'react';

function getInnerWidth() {
  return window.innerWidth;
}

export default function useInnerWidth() {
  const [windowWidth, setWindowWidth] = useState(getInnerWidth());
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getInnerWidth());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}
