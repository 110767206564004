const IconLink = () => {
  return (
    <svg
      width="26"
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-1"
    >
      <path
        d="M10.7082 2.05518C11.3229 1.74964 12.0452 1.65873 12.7602 1.85032C14.3162 2.26723 15.2395 3.86655 14.8226 5.42249L12.4501 14.2768C12.0332 15.8328 10.4339 16.7561 8.87792 16.3392C7.32198 15.9223 6.39862 14.323 6.81553 12.767L7.76453 9.2253C7.87174 8.8252 7.6343 8.41395 7.2342 8.30674C6.8341 8.19954 6.42285 8.43698 6.31564 8.83708L5.36664 12.3788C4.73531 14.7349 6.13355 17.1568 8.4897 17.7881C10.8458 18.4194 13.2677 17.0212 13.899 14.665L16.2715 5.81072C16.9028 3.45458 15.5046 1.03275 13.1484 0.401427C12.067 0.111652 10.9699 0.249988 10.0405 0.711984C9.47065 0.99527 8.96263 1.4007 8.5581 1.90504C8.29893 2.22815 8.35076 2.70019 8.67387 2.95936C8.99699 3.21853 9.46902 3.1667 9.7282 2.84358C9.99477 2.51125 10.3306 2.24292 10.7082 2.05518Z"
        stroke={'#5142FF'}
        fill={'#5142FF'}
      />
      <path
        d="M4.2228 8.2763C4.63972 6.72036 6.23903 5.79699 7.79498 6.21391C9.35092 6.63082 10.2743 8.23013 9.85737 9.78608L8.90837 13.3278C8.80116 13.7279 9.0386 14.1392 9.4387 14.2464C9.8388 14.3536 10.25 14.1161 10.3573 13.716L11.3063 10.1743C11.9376 7.81816 10.5393 5.39634 8.1832 4.76502C5.82706 4.13369 3.40524 5.53193 2.77392 7.88807L0.401407 16.7424C-0.229919 19.0985 1.16832 21.5204 3.52446 22.1517C4.60592 22.4415 5.70301 22.3031 6.63238 21.8411C7.20225 21.5578 7.71027 21.1524 8.1148 20.6481C8.37397 20.325 8.32214 19.8529 7.99903 19.5937C7.67591 19.3346 7.20388 19.3864 6.9447 19.7095C6.67813 20.0419 6.34234 20.3102 5.96467 20.4979C5.35003 20.8035 4.62768 20.8944 3.91269 20.7028C2.35675 20.2859 1.43338 18.6866 1.8503 17.1306L4.2228 8.2763Z"
        stroke={'#5142FF'}
        fill={'#5142FF'}
      />
    </svg>
  );
};

export default IconLink;
