import { createContext, useContext, useEffect, useState } from 'react';
import SecureLS from 'secure-ls';

export const AuthProdiverContext = createContext();
export const AuthProdiverContextDispatcher = createContext();

const AuthProdiver = ({ children }) => {
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const [userauth, setuserauth] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(secureLocalStorage.get('userAuth') || false);
    setuserauth(userData);
  }, []);

  useEffect(() => {
    userauth && secureLocalStorage.set('userAuth', JSON.stringify(userauth));
  }, [userauth]);

  return (
    <AuthProdiverContext.Provider value={userauth}>
      <AuthProdiverContextDispatcher.Provider value={setuserauth}>
        {children}
      </AuthProdiverContextDispatcher.Provider>
    </AuthProdiverContext.Provider>
  );
};

export default AuthProdiver;

export const useAuth = () => useContext(AuthProdiverContext);
export const useAuthAction = () => useContext(AuthProdiverContextDispatcher);
