import IMAGES from 'constants/images';
import { useEffect } from 'react';
import { createRipples } from 'react-ripples';
import { toast } from 'react-toastify';
import IconClose from 'utils/icons/IconClose';

const ModalPleaseLogin = ({ closeHandler, isShow }) => {
  useEffect(() => {
    toast.dismiss();
  }, [closeHandler]);

  const RippleBtn = createRipples({
    color: 'rgba(255, 255, 255, .2)',
    during: 1500,
  });

  return (
    <div>
      <div
        className={`modalOverlay ${
          isShow ? 'visible bg-black/30' : 'invisible opacity-0'
        }`}
      />
      <div
        className={`modalAnimationShowAndHide ${
          isShow
            ? 'scale-1 !z-9999 visible opacity-100'
            : 'invisible !-z-10 scale-75 opacity-0'
        }`}
      >
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex w-full items-center justify-center`}
        >
          <div
            onClick={closeHandler}
            className={`fixed bottom-0 left-0 right-0 top-0`}
          />
          <div
            className={`relative z-20 flex w-[90%] flex-col items-end justify-between gap-6 rounded-[25px] bg-white p-4 transition-all duration-300 md:w-[430px]`}
          >
            <div className="flex w-full items-start justify-end">
              <button
                className={`rounded-full p-2.5 transition-all duration-500 hover:bg-gray-100`}
                onClick={() => closeHandler()}
              >
                <IconClose />
              </button>
            </div>
            <div className="flex w-full flex-col items-center gap-4">
              <img
                src={IMAGES.website.routes.global.failed}
                className="mb-4 h-[100px] md:h-[120px]"
                alt=""
              />
              <div className="flex flex-col items-center gap-4">
                <p className="text-[20px] font-bold">Oops!</p>
                <p className="w-[300px] text-center text-[17px] font-medium leading-6 sm:w-[430px] sm:text-[18px]">
                  You must login to continue.
                </p>
              </div>
            </div>
            <RippleBtn className="flex w-full justify-center">
              <button
                className="mx-2 w-full rounded-lg border border-[#5142ff] bg-[#5142ff] py-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] md:w-[400px]"
                onClick={() => closeHandler()}
              >
                Done
              </button>
            </RippleBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPleaseLogin;
