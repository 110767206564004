import AdblockDetector from 'adblock-detector';
import FloatingBee from 'components/website/global/floatingBee';
import ModalDetectAdBlocker from 'components/website/modals/ModalDetectAdBlocker';
import ModalDetectGoogleTranslate from 'components/website/modals/ModalDetectGoogleTranslate';
import ModalDetectWebsiteIsOpenInAnotherTab from 'components/website/modals/ModalDetectWebsiteIsOpenInAnotherTab';
import GameImagePlay from 'components/website/routes/games/gameImage/GameImagePlay';
import GameLinkPlay from 'components/website/routes/games/gameLink/GameLinkPlay';
import GameLyricsPlay from 'components/website/routes/games/gameLyrics/GameLyricsPlay';
import GameTaskPlay from 'components/website/routes/games/gameTask/GameTaskPlay';
import HowToPlay from 'components/website/routes/games/howToPlay/HowToPlay';
import StartGameCountDown from 'components/website/routes/games/howToPlay/StartGameCountDown';
import DisableDevtool from 'disable-devtool';
import GrantAccessPlaySoundProvider from 'providers/GrantAccessPlaySoundProvieder';
import { lazy, Suspense, useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SecureLS from 'secure-ls';
import './App.scss';
import Layout from './layout/Layout';
import AuthProdiver from './providers/AuthProvider';
import AuthAdminProvider from './providers/DashboardAuthProvider';

//! lazy import routes
const HomePage = lazy(() => import('./pages/home'));
const UserChangePassword = lazy(() => import('./pages/user-change-password'));
const ResetPassword = lazy(() => import('./pages/reset-password'));
const LeaderBoard = lazy(() => import('./pages/leader-board'));
const ConfirmEmail = lazy(() => import('./pages/confirm-email'));
const Store = lazy(() => import('./pages/store'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const UserProfile = lazy(() => import('./pages/user-profile'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'));
const Eula = lazy(() => import('./pages/eula'));
const Terms = lazy(() => import('./pages/terms'));
const Games = lazy(() => import('./pages/games'));
const Aboutus = lazy(() => import('./pages/aboutus'));
const Contactus = lazy(() => import('./pages/contactus'));

const SuspendLoaderAnimation = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="flex h-[400px] w-full items-center justify-center md:h-[500px]">
          <ThreeDots height="60" width="60" radius="9" color="#5142FF" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

function App() {
  const isEnabledTaskGame = false;
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const userAuth = JSON.parse(secureLocalStorage.get('userAuth') || false);
  const isAdminOrLocalhost =
    userAuth?.username === 'admin' || window.location.hostname === 'localhost';
  // detect website is open in another tab/window
  const [isWebsiteOpenInAnotherTab, setIsWebsiteOpenInAnotherTab] =
    useState(false);
  useEffect(() => {
    if (isAdminOrLocalhost) return;
    const broadcastChannel = new BroadcastChannel('my_website_channel');
    const handleMessage = (event) => {
      if (event.data === 'website_open') {
        setIsWebsiteOpenInAnotherTab(true);
      } else {
        setIsWebsiteOpenInAnotherTab(false);
      }
    };
    broadcastChannel.addEventListener('message', handleMessage);
    broadcastChannel.postMessage('website_open');
    const intervalId = setInterval(() => {
      broadcastChannel.postMessage('website_open');
    }, 1000);
    window.addEventListener('unload', () => {
      broadcastChannel.postMessage('website_closed');
    });
    return () => {
      broadcastChannel.removeEventListener('message', handleMessage);
      clearInterval(intervalId);
      broadcastChannel.close();
    };
  }, []);

  // for lock right click
  const [key, setKey] = useState([]);
  DisableDevtool();
  // enable right-click for admin
  DisableDevtool.isSuspend = true;
  if (window.location.href.includes('S5PuhRGtN5GAhdS5')) {
    DisableDevtool.isSuspend = true;
  }
  // enable right-click in local-host
  if (isAdminOrLocalhost) {
    DisableDevtool.isSuspend = true;
  }

  /*
  if(route is-not dashboard) {
    if(key.length === 10 && all index is 'u') {
      disable right-click
    } else {
      keyState.length < 10 ? concat keyboard key to state : set current keyboard key to state
      enable right-click
    }
  }
  */
  useEffect(() => {
    const handleKey = (e) => {
      if (key.every((element) => element === 'u') && key.length === 10) {
        DisableDevtool.isSuspend = true;
      } else {
        DisableDevtool.isSuspend = false;
        setKey((prev) => (key.length < 10 ? prev.concat(e.key) : [e.key]));
      }
    };
    if (!window.location.href.includes('S5PuhRGtN5GAhdS5')) {
      window.addEventListener('keydown', handleKey);
      return () => window.removeEventListener('keydown', handleKey);
    }
  }, [key]);

  // for detect ad blocker
  let userHasAdblock = false;
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    userHasAdblock = false;
  } else {
    userHasAdblock = AdblockDetector.detect();
  }
  if (userHasAdblock) {
    document.querySelector('html').classList.add('overflow-y-hidden');
  }

  // for detect google translate
  const userHasGoogleTranslate =
    document.querySelector('html').getAttribute('lang') === 'fa';

  return (
    <GrantAccessPlaySoundProvider>
      <AuthProdiver>
        <AuthAdminProvider>
          <ToastContainer
            position="top-center"
            hideProgressBar={false}
            newestOnTop={false}
            draggable
            limit={1}
            className="p-[15px]"
          />
          <ModalDetectAdBlocker isShow={userHasAdblock} />
          <ModalDetectWebsiteIsOpenInAnotherTab
            isShow={isWebsiteOpenInAnotherTab}
          />
          <ModalDetectGoogleTranslate isShow={userHasGoogleTranslate} />
          {/* <FloatingBee /> */}
          <BrowserRouter>
            <Layout>
              <Routes>
                {/* dashboard */}
                <Route path="/S5PuhRGtN5GAhdS5" element={<Dashboard />} />
                {/* home */}
                <Route
                  index
                  element={
                    <SuspendLoaderAnimation>
                      <HomePage />
                    </SuspendLoaderAnimation>
                  }
                />
                {/* games */}
                <Route path="/games">
                  <Route
                    index
                    element={
                      <SuspendLoaderAnimation>
                        <Games />
                      </SuspendLoaderAnimation>
                    }
                  />
                  {/* game lyrics */}
                  <Route path={isEnabledTaskGame ? 'lyrics-private' : 'lyrics'}>
                    <Route index element={<Navigate to="/" replace />} />
                    <Route
                      path="how-to-play"
                      element={
                        <SuspendLoaderAnimation>
                          <HowToPlay gameName={'lyrics-master'} />
                        </SuspendLoaderAnimation>
                      }
                    />
                    <Route
                      path="timer"
                      element={
                        <SuspendLoaderAnimation>
                          <StartGameCountDown selectedGame={'lyrics-master'} />
                        </SuspendLoaderAnimation>
                      }
                    />
                    <Route
                      path="play"
                      element={
                        <SuspendLoaderAnimation>
                          <GameLyricsPlay />
                        </SuspendLoaderAnimation>
                      }
                    />
                  </Route>
                  {/* game image */}
                  <Route path={isEnabledTaskGame ? 'image-private' : 'image'}>
                    <Route index element={<Navigate to="/" replace />} />
                    <Route
                      path="how-to-play"
                      element={
                        <SuspendLoaderAnimation>
                          <HowToPlay gameName={'guess-image'} />
                        </SuspendLoaderAnimation>
                      }
                    />
                    <Route
                      path="timer"
                      element={
                        <SuspendLoaderAnimation>
                          <StartGameCountDown selectedGame={'guess-image'} />
                        </SuspendLoaderAnimation>
                      }
                    />
                    <Route
                      path="play"
                      element={
                        <SuspendLoaderAnimation>
                          <GameImagePlay />
                        </SuspendLoaderAnimation>
                      }
                    />
                  </Route>
                  {/* game song */}
                  <Route
                    path={isEnabledTaskGame ? 'song-private' : 'song'}
                    element={
                      <SuspendLoaderAnimation>
                        <HowToPlay gameName={'song-magic'} />
                      </SuspendLoaderAnimation>
                    }
                  />
                  {/* game task */}
                  <Route path={isEnabledTaskGame ? "task" : "task-private"}>
                    <Route index element={<Navigate to="/" replace />} />
                    <Route
                      path="play"
                      element={
                        <SuspendLoaderAnimation>
                          <GameTaskPlay />
                        </SuspendLoaderAnimation>
                      }
                    />
                    <Route
                      path="timer"
                      element={
                        <SuspendLoaderAnimation>
                          <StartGameCountDown selectedGame={'task-group'} />
                        </SuspendLoaderAnimation>
                      }
                    />
                  </Route>
                  {/* game link */}
                  <Route path="link-private">
                    <Route index element={<Navigate to="/" replace />} />
                    <Route
                      path="play"
                      element={
                        <SuspendLoaderAnimation>
                          <GameLinkPlay />
                        </SuspendLoaderAnimation>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="/store"
                  element={
                    <SuspendLoaderAnimation>
                      <Store />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/leaderboard"
                  element={
                    <SuspendLoaderAnimation>
                      <LeaderBoard />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <SuspendLoaderAnimation>
                      <ResetPassword />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/confirm-email"
                  element={
                    <SuspendLoaderAnimation>
                      <ConfirmEmail />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <Suspense>
                      <PrivacyPolicy />
                    </Suspense>
                  }
                />
                <Route
                  path="/eula"
                  element={
                    <SuspendLoaderAnimation>
                      <Eula />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/terms"
                  element={
                    <SuspendLoaderAnimation>
                      <Terms />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/aboutus"
                  element={
                    <SuspendLoaderAnimation>
                      <Aboutus />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/contactus"
                  element={
                    <SuspendLoaderAnimation>
                      <Contactus />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/user-change-password"
                  element={
                    <SuspendLoaderAnimation>
                      <UserChangePassword />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route
                  path="/user-profile"
                  element={
                    <SuspendLoaderAnimation>
                      <UserProfile />
                    </SuspendLoaderAnimation>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </AuthAdminProvider>
      </AuthProdiver>
    </GrantAccessPlaySoundProvider>
  );
}

export default App;
