import IMAGES from 'constants/images';

const ModalDetectGoogleTranslate = ({ isShow }) => {
  return (
    <div>
      <div
        className={`modalOverlay ${
          isShow ? 'visible bg-black/30' : 'invisible opacity-0'
        }`}
      />
      <div
        className={`modalAnimationShowAndHide ${
          isShow
            ? 'scale-1 !z-9999 visible opacity-100'
            : 'invisible !-z-10 scale-75 opacity-0'
        }`}
      >
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex w-full items-center justify-center`}
        >
          <div
            className={`relative z-20 flex w-[90%] flex-col items-end justify-between gap-6 rounded-[25px] bg-white p-4 transition-all duration-300 md:w-[520px]`}
          >
            <div className="flex w-full flex-col items-center gap-4">
              <img
                src={IMAGES.website.routes.global.failed}
                className="mb-2 h-[100px] md:h-[120px]"
                alt=""
              />
              <div className="flex flex-col items-center gap-4">
                <p className="text-[22px] font-bold">Oops!</p>
                <p className="w-[300px] text-center text-[17px] font-medium leading-6 sm:w-[430px] sm:text-[18px]">
                  sorry, disable google translate and try again!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetectGoogleTranslate;
