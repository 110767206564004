import axios from 'axios';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: 'https://api.intrestellar.tech/',
  headers: {
    'Content-Type': 'application/json',
  },
});

const http = {
  get: instance.get,
  post: instance.post,
  patch: instance.patch,
  delete: instance.delete,
  put: instance.put,
};

// Set the interceptors after creating the http object
instance.interceptors.response.use(undefined, (error) => {
  if (!error.response) {
    toast.error('please check network connection!');
  } else if (
    error &&
    error.response &&
    (error.response.data.message === 'Your account is Suspend!' ||
      error.response.data.message === 'User Authentication Needed.')
  ) {
    localStorage.removeItem('userAuth');
    localStorage.removeItem('questionPackAnswredNumber');
    window.location.reload(false);
  } else if(error.response.data.status === 500) {
    toast.error('an unexpected error occurred...');
  }
  return Promise.reject(error);
});

export default http;
