import { SkeletonGameLink } from 'components/global/skeleton';
import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import AdsenseTop from 'components/website/adsense/top';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';
import { useAuthAction } from 'providers/AuthProvider';
import { useEffect, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Oval } from 'react-loader-spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import IconLink from 'utils/icons/iconLink';

const GameLinkPlay = () => {
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const userAuth = JSON.parse(secureLocalStorage.get('userAuth') || false);
  const setAuth = useAuthAction();
  const [activedCircle, setActivedCircle] = useState('loader');
  const [activedStep, setActivedStep] = useState('link'); // 'link' | 'question'
  const [link, setLink] = useState('');
  const [questionData, setQuestionData] = useState([]);
  const questionAnswersRef = useRef();
  const [questionPoint, setQuestionPoint] = useState(0);
  const [isShowNextLinkBtn, setIsShowNextLinkBtn] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const width = useInnerWidth();
  const queryType = searchParams.get('type');
  const currectQuestionIndex = useRef(null);

  // user not-loggined or is-not type=video or type=music in query string ? redirect to home page : fetch link url
  useEffect(() => {
    if (!userAuth || (queryType !== 'video' && queryType !== 'music')) {
      navigate('/');
    } else {
      getLinkUrlHandler();
    }
  }, []);

  // call in first render
  const getLinkUrlHandler = () => {
    http
      .get(
        `/questions/avgame?type=${queryType === 'music' ? 'audio' : 'video'}`,
        {
          headers: { Authorization: userAuth.token },
        },
      )
      .then(({ data }) => {
        // change rouded circle from 'loader' to 'timer' / set state fetched url link
        setActivedCircle('timer');
        setLink(data.link);
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          'First Pass this link then request a new one!'
        ) {
          toast.error('try in a few moments ...');
        }
      });
  };

  // call after link timer is 0
  const getQuestionHandler = () => {
    setActivedCircle('loader');
    http
      .get(
        `/questions/avgame/linkquestion?type=${
          queryType === 'music' ? 'audio' : 'video'
        }`,
        {
          headers: { Authorization: userAuth.token },
        },
      )
      .then(({ data }) => {
        setActivedCircle('timer');
        setActivedStep('question');
        setQuestionData([data.title, data.options]);
      });
  };

  // call after question timer is 0 or select answer by user
  const getAnswerForQuestionHandler = (event) => {
    // 1 - click user to answer-btn or finish time
    // if timer finish or clicked answer button disable click to all buttons
    questionAnswersRef.current.classList.add('pointer-events-none');
    //* add border to clicked answer button by user
    if (event) {
      questionAnswersRef.current.children[
        event.target.getAttribute('button-key')
      ].classList.add('!border-[#5142FF]');
    }
    // add selected answer to variable for send to /questions/text/check
    let selectedAnswer = event?.target.innerText;
    // show spiner-loader before get answer result (show failed or success icon after get result)
    setActivedCircle('loader');
    // 2 - send request to backend for current answer
    http
      .post(`/questions/avgame/done/`, event && { key: selectedAnswer }, {
        headers: { Authorization: userAuth.token },
      })
      .then(({ data }) => {
        // update user-point from userPoints property backend
        const userData = JSON.parse(secureLocalStorage.get('userAuth'));
        userData.points = data.response.userPoints;
        setAuth(userData);
        // set current game step point to state for show to user
        setQuestionPoint(data.response.thisGamePoint);
        //* select four-answer-button parent
        const answers = questionAnswersRef.current.children;
        // find currect-answer by key and save to ref
        for (var i = 0; i < answers.length; i++) {
          if (answers[i].outerText === data.key) {
            currectQuestionIndex.current =
              answers[i].getAttribute('button-key');
          }
        }
        // 3 - proccess backend-response
        if (data.message === 'Your answer was not correct!') {
          // change spiner-loader to failed-icon
          setActivedCircle('failed');
          // add bg-red-400 and remove-border-added and text-white for not-correct-selected-answer
          if (event) {
            questionAnswersRef.current.children[
              event.target.getAttribute('button-key')
            ].classList.add(
              '!bg-red-400',
              '!border-transparent',
              '!text-white',
            );
          }
          // add bg-green-400 for correct-answer-sended-from-backend
          questionAnswersRef.current.children[
            currectQuestionIndex.current
          ].classList.add('!bg-green-400');
        } else if (data.message === 'Your answer was correct!') {
          // if selected-button is correct add bg-green-400 and hide border
          questionAnswersRef.current.children[
            event.target.getAttribute('button-key')
          ].classList.add('!bg-green-400', '!border-transparent');
          // replace icon success to spiner-loader
          setActivedCircle('success');
        }
        // 4 - show button for next-question or get-result
        setTimeout(() => {
          setIsShowNextLinkBtn(true);
          setActivedCircle('point');
        }, 3000);
      })
      .catch(() => {
        toast.dismiss();
      });
  };

  return (
    <>
      <div className="container mb-8 mt-6 grid h-full grid-cols-1 md:grid-cols-5 xl:px-24">
        <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
          {width > 768 && <AdsenseLeft />}
        </div>
        <div className="col-span-3 flex h-full flex-col items-center justify-between">
          <div className="block w-full rounded-lg">
            <AdsenseTop />
          </div>
          <div className="my-4 flex flex-col items-center gap-4 rounded-2xl border-2 bg-[#edecec] p-5">
            <p className="text-center text-[18px] font-semibold">
              {queryType === 'music' ? 'Listen to Music' : 'Watch video'}
            </p>
            {/* timer */}
            <div className="relative flex h-[60px] w-[60px] items-center justify-center">
              {activedCircle === 'timer' ? (
                <div
                  className={`countDownCircleTimer absolute bottom-0 left-0 right-0 top-0 text-[20px] font-black text-[#5142FF]`}
                >
                  <CountdownCircleTimer
                    isPlaying={true}
                    duration={activedStep === 'link' ? 60 : 10}
                    colors={'#5142FF'}
                    size={56}
                    strokeWidth={5}
                    trailColor={'#d8d8d8'}
                    rotation={'counterclockwise'}
                    onComplete={() => {
                      activedStep === 'link'
                        ? getQuestionHandler()
                        : getAnswerForQuestionHandler(null);
                    }}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </div>
              ) : activedCircle === 'loader' ? (
                <div className={'absolute bottom-0 left-0 right-0 top-0'}>
                  <Oval
                    height={57}
                    width={57}
                    color="#5142FF"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#777"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </div>
              ) : activedCircle === 'success' ? (
                <img
                  className={`absolute bottom-0 left-0 right-0 top-0`}
                  src={IMAGES.website.routes.games.global.success}
                  width={55}
                  alt=""
                />
              ) : activedCircle === 'failed' ? (
                <img
                  className={`absolute bottom-0 left-0 right-0 top-0`}
                  src={IMAGES.website.routes.global.failed}
                  width={55}
                  alt=""
                />
              ) : activedCircle === 'point' ? (
                <div
                  className={`flex h-[55px] w-[55px] items-center justify-center rounded-full border-[5px] text-[20px] font-black ${
                    questionPoint === 0
                      ? 'border-yellow-400 text-yellow-400'
                      : questionPoint < 0
                      ? 'border-red-400 text-red-400'
                      : questionPoint > 0
                      ? 'border-green-500 text-green-500'
                      : ''
                  }`}
                >
                  {Number(questionPoint) > 0 && '+'}
                  {questionPoint}
                </div>
              ) : null}
            </div>
            {/* link */}
            {!!(activedStep === 'link') && (
              <div className="mt-3 px-14">
                {link.length ? (
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex h-[100px] w-[100px] items-center justify-center rounded-2xl border-[3px] border-dashed border-[#5142ff] bg-white text-center transition-all duration-300 hover:bg-gray-100"
                  >
                    <IconLink />
                  </a>
                ) : (
                  <SkeletonGameLink />
                )}
              </div>
            )}
            {/* question */}
            {!!(activedStep === 'question') && (
              <div className="flex flex-col gap-4">
                {/* lyrics text */}
                <div className="text-center">
                  <div className="min-h-[70px] w-[300px] text-[18px] font-semibold leading-8 sm:w-[400px] sm:px-8 xl:min-h-[60px] xl:w-[500px]">
                    <p>{questionData[0]}</p>
                  </div>
                </div>
                {/* answer buttons */}
                <div
                  className="grid grid-cols-2 gap-4"
                  ref={questionAnswersRef}
                >
                  {questionData[1].map((item, index) => {
                    return (
                      <button
                        button-key={index}
                        onClick={(e) => getAnswerForQuestionHandler(e)}
                        className="h-[100px] w-full rounded-2xl border border-white bg-white text-[16px] font-medium shadow-sm shadow-slate-200 outline-none transition-all duration-300 hover:bg-gray-50 xl:h-[140px]"
                        key={index}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
            {/* next link button */}
            {isShowNextLinkBtn && (
              <div className="flex w-full justify-center">
                <button
                  className={`mt-3 w-[250px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea]`}
                  onClick={() => window.location.reload()}
                >
                  Next Link
                </button>
              </div>
            )}
          </div>
          <div className="w-full rounded-xl">
            <AdsenseBottom />
          </div>
        </div>
        <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
          {width > 768 && <AdsenseRight />}
        </div>
      </div>
    </>
  );
};

export default GameLinkPlay;
