import React, { useContext } from 'react';

const DashboardAuthContext = React.createContext();
const DashboardAuthContextDispatcher = React.createContext();

const DashboardAuthProvider = ({ children }) => {
  const [state, setState] = React.useState(0);

  return (
    <>
      <DashboardAuthContext.Provider value={state}>
        <DashboardAuthContextDispatcher.Provider value={setState}>
          {children}
        </DashboardAuthContextDispatcher.Provider>
      </DashboardAuthContext.Provider>
    </>
  );
};

export default DashboardAuthProvider;

export const useDashboardAuth = () => useContext(DashboardAuthContext);
export const useDashboardAuthAction = () =>
  useContext(DashboardAuthContextDispatcher);
