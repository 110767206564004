import {
  SkeletonGameMusic,
  SkeletonGameMusicLargeScreen,
  SkeletonGameMusicResult,
} from 'components/global/skeleton';
import AdsenseBottom from 'components/website/adsense/bottom';
import AdsenseLeft from 'components/website/adsense/left';
import AdsenseRight from 'components/website/adsense/right';
import AdsenseTop from 'components/website/adsense/top';
import IMAGES from 'constants/images';
import useInnerWidth from 'hooks/useInnerWidth';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import { useEffect, useRef, useState } from 'react';
import emoji from 'react-easy-emoji';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import GameMusicAnswer from './GameMusicAnswer';
import GameMusicSongInformation from './GameMusicSongInformation';

const GameMusicPlay = ({ selectedCategory }) => {
  const setAuth = useAuthAction();
  const width = useInnerWidth();
  const navigate = useNavigate();
  const userAuth = useAuth();
  const paginationRef = useRef();
  const [isMounted, setIsMounted] = useState(false);
  const [answerData, setAnswerData] = useState(false);
  const [activePaginationNumber, setActivePaginationNumber] = useState(false);
  const [finishQuestion, setFinishQuestion] = useState(false);
  const [songInformation, setSongInformation] = useState(false);
  const [getQuestionResult, setGetQetQuestionResult] = useState(false);

  const songInformationHanlder = (data) => {
    setSongInformation(data);
  };

  const playGameHandler = () => {
    setAnswerData(false);
    setSongInformation(false);
  };

  const getQuestionResultHandler = () => {
    setGetQetQuestionResult(true);
  };

  const [userNeedLogout, setUserNeedLogout] = useState(false);

  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const localStorageQuestionPackAnswred = JSON.parse(
    secureLocalStorage.get('questionPackAnswredNumber') || false,
  );
  const [isLimitedUser, setIsLimitedUser] = useState(null);

  useEffect(() => {
    if (finishQuestion) {
      if (localStorageQuestionPackAnswred <= 10) {
        secureLocalStorage.set(
          'questionPackAnswredNumber',
          JSON.stringify(
            parseInt(parseInt(localStorageQuestionPackAnswred) + 1),
          ),
        );
      } else {
        setUserNeedLogout(true);
      }
    }
  }, [finishQuestion]);

  useEffect(() => {
    if (userAuth) {
      if (!answerData) {
        if (isMounted) {
          if (!finishQuestion) {
            http
              .get(
                `/questions/music?category=${encodeURIComponent(
                  selectedCategory,
                )}`,
                { headers: { Authorization: userAuth.token } },
              )
              .then(({ data }) => {
                //* if has newQuestion backend-reslut for load questions-music-game
                if (data?.question?.message === 'New question :') {
                  //* song url and four-answers and question-token and set state to answerData and load question-music-game
                  setAnswerData([
                    data.question.music,
                    data?.question.answers,
                    data?.token,
                  ]);
                  //* set question-number for pagination-bottom
                  setActivePaginationNumber(
                    parseInt(data.question.questionNumber),
                  );
                } else if (data?.message === 'You answered three questions!') {
                  //* if finish tree question show tree-qustion-result
                  setFinishQuestion([
                    String(data.questionsPoints),
                    data.correctsCount,
                    data.quizQuestions,
                  ]);
                  //* if isLimited is true show text 'Note: You reached your hourly limit.'
                  setIsLimitedUser(data.isLimited);
                }
              })
              .catch((error) => {
                if (
                  error.response.data.message ===
                  'No more questions available to answer!'
                ) {
                  toast.error('you have answered all the questions!');
                }
              });
          }
        }
        setIsMounted(true);
      }
    }
  }, [userAuth, navigate, isMounted, answerData, finishQuestion]);

  useEffect(() => {
    if (answerData && !finishQuestion) {
      let paginations = [];
      const activePagination =
        paginationRef.current.children[activePaginationNumber];

      paginations.push(...paginationRef.current.children);
      paginations.forEach((i) => {
        i.classList.remove('!bg-[#5142FF]');
      });

      activePagination.classList.add('!bg-[#5142FF]');
    }
  }, [activePaginationNumber, answerData]);

  return (
    <>
      {/* music question box base */}
      <section>
        {/* if backend result is question */}
        {userAuth && !finishQuestion && !songInformation && (
          <div className="container mb-8 mt-6 grid h-full grid-cols-1 md:grid-cols-5 xl:px-24">
            <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
              {width > 768 && <AdsenseLeft />}
            </div>
            <div className="col-span-3 flex h-full flex-col items-center justify-between">
              <div className="block w-full rounded-lg">
                <AdsenseTop />
              </div>
              <div className={`w-full py-4 ${getQuestionResult && 'pt-0'}`}>
                <div className="flex w-full justify-center">
                  {/* show question-music */}
                  {answerData && (
                    <div className="my-8 w-[360px] rounded-2xl border-2 bg-[#edecec] px-6 pt-3 sm:w-[400px] lg:w-[500px]">
                      <p
                        className={`mt-4 text-center text-[18px] font-semibold ${
                          getQuestionResult && 'hidden'
                        }`}
                      >
                        Magic Challenge
                      </p>
                      <div className="my-6 w-full">
                        <GameMusicAnswer
                          songInformationHanlder={songInformationHanlder}
                          activePaginationNumber={activePaginationNumber}
                          songUrl={answerData[0]}
                          questions={answerData[1]}
                          questionToken={answerData[2]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <div
                          ref={paginationRef}
                          className="mb-4 flex justify-center gap-2 rounded-full bg-white p-2"
                        >
                          <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                          <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                          <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* show skeleton after question-music fetch */}
                  {!answerData && (
                    <>
                      {getQuestionResult && (
                        <div className="w-full">
                          <div className="w-full">
                            <div className="mb-4 w-full rounded-xl">
                              {<AdsenseTop />}
                            </div>
                          </div>
                          <div className="flex w-full justify-center">
                            <div className="w-[90%] rounded-2xl border-2 bg-[#edecec] px-6 pt-3 md:w-full">
                              <SkeletonGameMusicResult />
                            </div>
                          </div>
                        </div>
                      )}
                      {!getQuestionResult && (
                        <div className="flex w-full justify-center">
                          <div className="my-8 mt-8 flex h-[500px] w-[360px] flex-col items-center rounded-2xl border-2 bg-[#edecec] px-0 pt-3 sm:w-[400px] lg:w-[500px] xl:h-[580px]">
                            <p
                              className={`mt-4 text-center text-[18px] font-semibold ${
                                getQuestionResult && 'hidden'
                              }`}
                            >
                              Magic Challenge
                            </p>
                            <div className="my-4 xl:hidden">
                              <SkeletonGameMusic />
                            </div>
                            <div className="my-4 hidden xl:flex">
                              <SkeletonGameMusicLargeScreen />
                            </div>
                            <div
                              ref={paginationRef}
                              className="mb-4 flex justify-center gap-2 rounded-full bg-white p-2"
                            >
                              <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                              <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                              <button className="pointer-events-none h-2 w-2 rounded-full bg-[#EAEAEA] focus:outline-none" />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="w-full rounded-xl">
                <AdsenseBottom />
              </div>
            </div>
            <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
              {width > 768 && <AdsenseRight />}
            </div>
          </div>
        )}
        {/* if user click to button get-user-information */}
        {userAuth && !finishQuestion && songInformation && (
          <GameMusicSongInformation
            cover={songInformation[0]}
            musicName={songInformation[1]}
            artistName={songInformation[2]}
            spotifyLink={songInformation[3]}
            youtubeLink={songInformation[4]}
            questionNumber={songInformation[5]}
            playGameHandler={playGameHandler}
            getQuestionResultHandler={getQuestionResultHandler}
          />
        )}
        {/* if backend response is result */}
        {userAuth && finishQuestion && (
          <div className="mb-8 mt-6 grid h-full grid-cols-1 md:container md:grid-cols-5 xl:px-24">
            <div className="col-span-1 mr-4 hidden w-full rounded-lg md:block lg:mr-8">
              {width > 768 && <AdsenseLeft />}
            </div>
            <div className="col-span-3 flex h-full flex-col items-center">
              <div className="w-full px-3">
                <div className="w-full rounded-xl">{<AdsenseTop />}</div>
              </div>
              <div className="my-4 flex w-[90%] flex-col rounded-2xl border-2 bg-[#edecec] p-4 md:w-full">
                <p className="mx-4 mb-3 text-[30px] font-bold">Result</p>
                <p className="mx-4 mb-1 flex gap-2 text-[20px] font-bold text-[#5142FF]">
                  {String(finishQuestion[1])}/3 ,{' '}
                  {finishQuestion[1] <= 2
                    ? emoji('You Can Do Better 😫')
                    : emoji('You did A Great Job 🤩')}
                </p>
                <div className="mx-4 text-[15px] font-medium text-[#727171]">
                  {!(Math.sign(parseInt(finishQuestion[0])) === -1) &&
                    parseInt(finishQuestion[0]) === 0 && (
                      <p>you did not earn any points!</p>
                    )}
                  {!(Math.sign(parseInt(finishQuestion[0])) === -1) &&
                    parseInt(finishQuestion[0]) !== 0 && (
                      <p className="text-[18px] font-bold">
                        You earned{' '}
                        <span className="text-[18px] text-gray-600">
                          {Math.abs(parseInt(finishQuestion))}
                        </span>{' '}
                        points!
                      </p>
                    )}
                  {!(Math.sign(parseInt(finishQuestion[0])) !== -1) &&
                    parseInt(finishQuestion[0]) !== 0 && (
                      <p className="text-[18px] font-bold">
                        You Lost{' '}
                        <span className="text-[18px] text-gray-600">
                          {Math.abs(parseInt(finishQuestion))}
                        </span>{' '}
                        points!
                      </p>
                    )}
                  {isLimitedUser && (
                    <p className="mt-1 text-[18px] font-bold text-orange-500">
                      Note: You reached your hourly limit.
                    </p>
                  )}
                </div>
                <ul className="relative mt-4 space-y-2">
                  {finishQuestion[2].map((item, index) => {
                    return (
                      <li key={index} className="group">
                        <div className="flex w-full items-center justify-between gap-2 rounded-lg px-4 py-2 transition-all duration-300 group-hover:bg-white">
                          <div className="flex items-center gap-2">
                            <span className="w-[17px] text-[20px] font-black text-[#929292] group-hover:text-[#5142FF]">
                              {index + 1}.
                            </span>
                            <img
                              src={item.cover}
                              className="h-[48px] w-[48px] rounded-lg"
                              alt=""
                            />
                            <div>
                              <p className="w-[150px] truncate font-bold sm:w-[200px] md:w-[200px] lg:w-[300px]">
                                {item.musicName}
                              </p>
                              <p className="w-[120px] truncate text-[15px] font-medium text-[#7E7E7E] sm:w-[170px] md:w-[170px] lg:w-[270px]">
                                {item.singerName}
                              </p>
                            </div>
                          </div>
                          <div className="flex gap-4">
                            <img
                              src={
                                item.status === 'correct'
                                  ? IMAGES.website.routes.games.global.success
                                  : IMAGES.website.routes.global.failed
                              }
                              className="h-[30px] w-[30px] group-hover:hidden"
                              alt=""
                            />
                            {item.youtubeLink && (
                              <a
                                href={item.youtubeLink}
                                className="hidden group-hover:flex"
                              >
                                <img
                                  alt=""
                                  src={IMAGES.website.routes.games.song.youtube}
                                />
                              </a>
                            )}
                            {item.spotifyLink && (
                              <a
                                href={item.spotifyLink}
                                className="hidden group-hover:flex"
                              >
                                <img
                                  alt=""
                                  src={IMAGES.website.routes.games.song.spotify}
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="mb-2 mt-8 flex w-full justify-center !overflow-auto">
                  <button
                    className={`w-[300px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-5 pt-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] md:w-[350px]`}
                    onClick={() => {
                      if (userNeedLogout) {
                        localStorage.removeItem('userAuth');
                        localStorage.removeItem('questionPackAnswredNumber');
                        toast.warn(' Please log in again', { autoClose: 3000 });
                        setTimeout(() => {
                          setAuth('');
                          window.open('/', '_self');
                        }, 5000);
                      } else {
                        setFinishQuestion(false);
                        setAnswerData(false);
                        setGetQetQuestionResult(false);
                      }
                    }}
                  >
                    Continue Quiz!
                  </button>
                </div>
              </div>
              <div className="w-full px-3">
                <div className="w-full rounded-xl">{<AdsenseBottom />}</div>
              </div>
            </div>
            <div className="col-span-1 ml-4 hidden w-full rounded-lg md:block lg:ml-8">
              {width > 768 && <AdsenseRight />}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default GameMusicPlay;
