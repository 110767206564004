const IconPhoto = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.3599 33.92L37.0998 19.3C34.9798 14.34 31.0799 14.14 28.4599 18.86L24.6799 25.68C22.7599 29.14 19.1799 29.44 16.6999 26.34L16.2599 25.78C13.6799 22.54 10.0399 22.94 8.17985 26.64L4.73985 33.54C2.31985 38.34 5.81985 44 11.1799 44H36.6999C41.8999 44 45.3999 38.7 43.3599 33.92Z"
        stroke="#B6B6B6"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M13.9399 16C17.2536 16 19.9399 13.3137 19.9399 10C19.9399 6.68629 17.2536 4 13.9399 4C10.6262 4 7.93994 6.68629 7.93994 10C7.93994 13.3137 10.6262 16 13.9399 16Z"
        stroke="#B6B6B6"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPhoto;
