const IconClose = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5303 1.53049C11.8232 1.2376 11.8232 0.762726 11.5303 0.469831C11.2374 0.176935 10.7625 0.176931 10.4696 0.469821L6.00002 4.93933L1.53051 0.469827C1.23762 0.176933 0.762742 0.176933 0.469848 0.469827C0.176955 0.762719 0.176955 1.23759 0.469849 1.53049L4.93935 5.99998L0.469674 10.4696C0.176779 10.7625 0.176774 11.2373 0.469665 11.5302C0.762556 11.8231 1.23743 11.8231 1.53032 11.5302L6.00001 7.06064L10.4697 11.5303C10.7626 11.8232 11.2375 11.8232 11.5304 11.5303C11.8232 11.2374 11.8232 10.7626 11.5304 10.4697L7.06068 5.99999L11.5303 1.53049Z"
        fill="#828282"
      />
    </svg>
  );
};

export default IconClose;
