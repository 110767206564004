import IMAGES from 'constants/images';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import { useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import SecureLS from 'secure-ls';
import http from 'services/httpService';

const GameLyricsAnswer = ({
  text,
  questions,
  questionToken,
  reRenderPlayHandler,
  activePaginationNumber,
}) => {
  const [isShowNextQuestionBtn, setIsShowNextQuestionBtn] = useState(false);
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const userAuth = useAuth();
  const questionAnswersRef = useRef();
  const currectQuestionIndex = useRef(null);
  const setAuth = useAuthAction();
  const [questionNumber, setQuestionNumber] = useState(null);
  const [activedCircle, setActivedCircle] = useState('timer');
  const [currentGameStepPoint, setCurrentGameStepPoint] = useState(null);

  const showQuestionAnswerHandler = (event, fetchApi) => {
    //! 1 - click user to answer-btn or finish time
    //* if timer finish or clicked answer button disable click to all buttons
    questionAnswersRef.current.classList.add('pointer-events-none');
    //* add border to clicked answer button by user
    if (event) {
      questionAnswersRef.current.children[
        event.target.getAttribute('button-key')
      ].classList.add('!border-[#5142FF]');
    }
    //* add selected answer to variable for send to /questions/text/check
    let selectedAnswer = event?.target.innerText;
    //* show spiner-loader before get answer result (show failed or success icon after get result)
    setActivedCircle('loader');
    //! 2 - send request to backend for current answer
    http
      .post(
        `/questions/text/${fetchApi}/`,
        fetchApi === 'check'
          ? { token: questionToken, key: selectedAnswer }
          : { token: questionToken },
        { headers: { Authorization: userAuth.token } },
      )
      .then(({ data }) => {
        //* update user-point from userPoints-property-backend
        if (data.userPoints) {
          const userData = JSON.parse(secureLocalStorage.get('userAuth'));
          userData.points = data.userPoints;
          setAuth(userData);
        }
        //* save question-number (1 or 2 or 3) and check question number is 3 show get result text for next question
        setQuestionNumber(data.questionNumber);
        //* set current game step point to state for show to user
        setCurrentGameStepPoint(data.thisGamePoint);
        //* select four-answer-button parent
        const answers = questionAnswersRef.current.children;
        //* find currect-answer by key and save to ref
        for (var i = 0; i < answers.length; i++) {
          if (answers[i].outerText === data.key) {
            currectQuestionIndex.current =
              answers[i].getAttribute('button-key');
          }
        }
        //! 3 - proccess backend-responsec
        if (data.message === 'Your answer was not correct!') {
          //* change spiner-loader to failed-icon
          setActivedCircle('failed');
          //* add bg-red-400 and remove-border-added and text-white for not-correct-selected-answer
          questionAnswersRef.current.children[
            event.target.getAttribute('button-key')
          ].classList.add('!bg-red-400', '!border-transparent', '!text-white');
          //* add bg-green-400 for correct-answer-sended-from-backend
          questionAnswersRef.current.children[
            currectQuestionIndex.current
          ].classList.add('!bg-green-400');
        } else if (data.message === 'Your time has been finished!') {
          //* add bg-green-400 for correct-answer-sended-from-backend
          questionAnswersRef.current.children[
            currectQuestionIndex.current
          ].classList.add('!bg-green-400');
          setActivedCircle('failed');
        } else if (data.message === 'Your answer was correct!') {
          //* if selected-button is correct add bg-green-400 and hide border
          questionAnswersRef.current.children[
            event.target.getAttribute('button-key')
          ].classList.add('!bg-green-400', '!border-transparent');
          //* replace icon success to spiner-loader
          setActivedCircle('success');
        }
        //! 4 - show button for next-question or get-result
        setTimeout(() => {
          setIsShowNextQuestionBtn(true);
          setActivedCircle('point');
        }, 3000);
      })
      .catch((error) => {
        toast.dismiss();
      });
  };

  let newText = text
    .split('{}')
    .join(
      '<span class="border-b-2 border-[#DEDEDE] relative bottom-1 mx-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>',
    );

  return (
    <>
      <div className="flex flex-col items-center gap-4">
        {/* circle loader, circle timer, circle success, circle failed */}
        <div className="relative flex h-[60px] w-[60px] items-center justify-center">
          {activedCircle === 'timer' ? (
            <div
              className={`countDownCircleTimer absolute bottom-0 left-0 right-0 top-0 text-[20px] font-black text-[#5142FF]`}
            >
              <CountdownCircleTimer
                isPlaying={true}
                duration={10}
                colors={'#5142FF'}
                size={56}
                strokeWidth={5}
                trailColor={'#d8d8d8'}
                rotation={'counterclockwise'}
                onComplete={() => {
                  showQuestionAnswerHandler(null, 'timeout');
                }}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
          ) : activedCircle === 'loader' ? (
            <div className={'absolute bottom-0 left-0 right-0 top-0'}>
              <Oval
                height={57}
                width={57}
                color="#5142FF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#777"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </div>
          ) : activedCircle === 'success' ? (
            <img
              className={`absolute bottom-0 left-0 right-0 top-0`}
              src={IMAGES.website.routes.games.global.success}
              width={55}
              alt=""
            />
          ) : activedCircle === 'failed' ? (
            <img
              className={`absolute bottom-0 left-0 right-0 top-0`}
              src={IMAGES.website.routes.global.failed}
              width={55}
              alt=""
            />
          ) : activedCircle === 'point' ? (
            <div
              className={`flex h-[55px] w-[55px] items-center justify-center rounded-full border-[5px] text-[20px] font-black ${
                currentGameStepPoint === 0
                  ? 'border-yellow-400 text-yellow-400'
                  : currentGameStepPoint < 0
                  ? 'border-red-400 text-red-400'
                  : currentGameStepPoint > 0
                  ? 'border-green-500 text-green-500'
                  : ''
              }`}
            >
              {Number(currentGameStepPoint) > 0 && '+'}
              {currentGameStepPoint}
            </div>
          ) : null}
        </div>
        {/* lyrics text */}
        <div className="text-center">
          <div className="min-h-[70px] w-[300px] text-[18px] font-semibold leading-8 sm:w-[400px] sm:px-8 xl:min-h-[60px] xl:w-[500px]">
            <div
              dangerouslySetInnerHTML={{
                __html: String(`${activePaginationNumber + 1}. ${newText}`),
              }}
            />
          </div>
        </div>
        {/* answer buttons */}
        <div className="grid grid-cols-2 gap-4" ref={questionAnswersRef}>
          {questions.map((item, index) => {
            return (
              <button
                button-key={index}
                onClick={(e) => showQuestionAnswerHandler(e, 'check')}
                className="h-[100px] w-[140px] rounded-2xl border border-white bg-white text-[16px] font-medium shadow-sm shadow-slate-200 outline-none transition-all duration-300 hover:bg-gray-50 sm:w-[150px] xl:h-[140px] xl:w-[220px]"
                key={index}
              >
                {item}
              </button>
            );
          })}
        </div>
        {/* next-question or get-result button */}
        {isShowNextQuestionBtn && (
          <div className="flex w-full justify-center">
            <button
              className={`mt-3 w-[300px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-5 pt-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] md:w-[350px]`}
              onClick={() => {
                reRenderPlayHandler();
              }}
            >
              {questionNumber === 3 ? 'Get Result' : 'Next Question'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default GameLyricsAnswer;
