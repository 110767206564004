import React, { useContext } from 'react';

const GrantAccessPlaySoundContext = React.createContext();
const GrantAccessPlaySoundContextDispatcher = React.createContext();

const GrantAccessPlaySoundProvider = ({ children }) => {
  const [state, setState] = React.useState(false);

  return (
    <>
      <GrantAccessPlaySoundContext.Provider value={state}>
        <GrantAccessPlaySoundContextDispatcher.Provider value={setState}>
          {children}
        </GrantAccessPlaySoundContextDispatcher.Provider>
      </GrantAccessPlaySoundContext.Provider>
    </>
  );
};

export default GrantAccessPlaySoundProvider;

export const useGrantAccessPlaySound = () =>
  useContext(GrantAccessPlaySoundContext);
export const useGrantAccessPlaySoundAction = () =>
  useContext(GrantAccessPlaySoundContextDispatcher);
