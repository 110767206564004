import ModalTransferGem from 'components/website/modals/ModalTransferGem';
import IMAGES from 'constants/images';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import { lazy, useEffect, useState } from 'react';
import { createRipples } from 'react-ripples';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import { createGlobalStyle } from 'styled-components';
import IconChevronBottom from 'utils/icons/IconChevronBottom';
import IconInstagram from 'utils/icons/iconInstagram';
import IconTelegram from 'utils/icons/iconTelegram';
import IconTransfer from 'utils/icons/IconTransfer';

//! modals lazy-import

const ModalSignup = lazy(() => import('components/website/modals/ModalSignup'));
const ModalForgetPassword = lazy(() =>
  import('components/website/modals/ModalForgetPassword'),
);
const ModalCheckMailResetPassword = lazy(() =>
  import('components/website/modals/ModalCheckMailResetPassword'),
);
const ModalSignin = lazy(() => import('components/website/modals/ModalSignin'));
const ModalCheckMailVerify = lazy(() =>
  import('components/website/modals/ModalCheckMailVerify'),
);

const GlobalStyle = createGlobalStyle`
  #root , #store, #Leaderboard, body, html {
    height: ${(props) =>
      props.isShowMenuMobile ? '0 !important' : 'auto !important'};
    overflow: ${(props) =>
      props.isShowMenuMobile ? 'hidden !important' : 'auto !important'};
  }
  #navLinks a.active {
    background: #F9F9F9;
    width: 100%;
  }
`;

const Layout = ({ children }) => {
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const userAuth = useAuth();
  const setAuth = useAuthAction();
  const htmlTag = document.querySelector('html');
  const navigate = useNavigate();
  const [userSignupData, setUserSignupData] = useState('');

  const updateUserSignupDataHandler = (props) => setUserSignupData(props);

  let userLocalStorage;

  try {
    userLocalStorage = JSON.parse(secureLocalStorage.get('userAuth') || false);
    JSON.parse(secureLocalStorage.get('questionPackAnswredNumber') || false);
  } catch (error) {
    localStorage.removeItem('userAuth');
    localStorage.removeItem('questionPackAnswredNumber');
  }

  const [isShowModalDesktopUserModal, setIsShowModalDesktopUserModal] =
    useState(false);
  const [isShowMenuMobile, setIsShowMenuMobile] = useState(false);
  const [isShowModalCheckMailVerify, setIsShowModalCheckMailVerify] =
    useState(false);
  const [isShowModalSignup, setIsShowModalSignup] = useState(false);
  const [isShowModalSignin, setIsShowModalSignin] = useState(false);
  const [isShowModalForgetPassword, setIsShowModalForgetPassword] =
    useState(false);
  const [
    isShowModalCheckMailResetPassword,
    setIsShowModalCheckMailResetPassword,
  ] = useState(false);
  const [isShowModalTransferGem, setIsShowModalTransferGem] = useState(false);

  useEffect(() => {
    setIsShowModalDesktopUserModal(false);
  }, [userAuth]);

  useEffect(() => {
    // update userAuth with fetched user auth data
    if (userLocalStorage) {
      http
        .get(`/users/exists`, {
          headers: { Authorization: userLocalStorage.token },
        })
        .then(({ data }) => {
          const newUserData = data.user;
          newUserData.gems = parseFloat(data.gems).toFixed(3);
          setAuth(data.user);
        });
    }
  }, []);

  // if isNot peropety 'gems' in userAuth && logout / for add gems to ghofly backend
  useEffect(() => {
    if (userLocalStorage && userLocalStorage.gems === undefined) {
      localStorage.removeItem('userAuth');
      localStorage.removeItem('questionPackAnswredNumber');
      setAuth('');
      window.location.reload(false);
    }
  }, [userLocalStorage]);

  const RippleBtn = createRipples({
    color: 'rgba(255, 255, 255, .2)',
    during: 1500,
  });

  const hiddenSignupHandler = () => {
    setIsShowModalSignup(false);
    htmlTag.classList.remove('overflow-y-hidden');
  };

  const showModalCheckMailResetPasswordHandler = () => {
    setIsShowModalCheckMailResetPassword(true);
    htmlTag.classList.add('overflow-y-hidden');
  };
  const hiddenModalCheckMailResetPasswordHandler = () => {
    setIsShowModalCheckMailResetPassword(false);
    htmlTag.classList.remove('overflow-y-hidden');
  };

  const showModalCheckMailVerifyHandler = () => {
    setIsShowModalCheckMailVerify(true);
    htmlTag.classList.add('overflow-y-hidden');
  };
  const hiddenModalCheckMailHandler = () => {
    setIsShowModalCheckMailVerify(false);
    htmlTag.classList.remove('overflow-y-hidden');
  };

  const showModalSigninHandler = () => {
    setIsShowModalSignin(true);
    htmlTag.classList.add('overflow-y-hidden');
  };
  const hiddenModalSigninHandler = () => {
    setIsShowModalSignin(false);
    htmlTag.classList.remove('overflow-y-hidden');
  };

  const showModalForgetPasswordHandler = () => {
    setIsShowModalForgetPassword(true);
    htmlTag.classList.add('overflow-y-hidden');
  };
  const hiddenModalForgetPasswordHandler = () => {
    setIsShowModalForgetPassword(false);
    htmlTag.classList.remove('overflow-y-hidden');
  };

  const showModalTrasferGemHandler = () => {
    setIsShowModalTransferGem(true);
    htmlTag.classList.add('overflow-y-hidden');
  };
  const hiddenModalTrasferGemHandler = () => {
    setIsShowModalTransferGem(false);
    htmlTag.classList.remove('overflow-y-hidden');
  };

  const closeMobileMenuHandler = () => {
    setIsShowMenuMobile(false);
    navigate('/');
  };

  isShowMenuMobile && window.scrollTo({ top: 0, left: 0 });

  return (
    <>
      <GlobalStyle
        isShowMenuMobile={isShowMenuMobile ? true : false}
        isShowModal={
          (isShowModalSignin ||
            isShowModalSignup ||
            isShowModalForgetPassword ||
            isShowModalCheckMailVerify ||
            isShowModalCheckMailResetPassword ||
            isShowModalTransferGem ||
            showModalCheckMailVerifyHandler) &&
          true
        }
      />
      {/* modals */}
      <div>
        <ModalSignup
          isShow={isShowModalSignup}
          signupDataUpdataHandler={updateUserSignupDataHandler}
          closeHandler={hiddenSignupHandler}
          showModalSigninHandler={showModalSigninHandler}
          showModalCheckMailVerifyHandler={showModalCheckMailVerifyHandler}
        />
        <ModalSignin
          isShow={isShowModalSignin}
          showForgetPasswordHandler={showModalForgetPasswordHandler}
          closeMobileMenuHandler={closeMobileMenuHandler}
          closeHandler={hiddenModalSigninHandler}
        />
        <ModalCheckMailResetPassword
          isShow={isShowModalCheckMailResetPassword}
          closeHandler={hiddenModalCheckMailResetPasswordHandler}
        />
        <ModalCheckMailVerify
          userData={{
            userName: userSignupData[0],
            email: userSignupData[1],
          }}
          isShow={isShowModalCheckMailVerify}
          closeHandler={hiddenModalCheckMailHandler}
        />
        <ModalForgetPassword
          isShow={isShowModalForgetPassword}
          showCheckMailHandler={showModalCheckMailResetPasswordHandler}
          closeHandler={hiddenModalForgetPasswordHandler}
        />
        <ModalTransferGem
          isShow={isShowModalTransferGem}
          closeHandler={hiddenModalTrasferGemHandler}
        />
      </div>
      <header className="z-50 flex items-center border-b border-[#f6f6f6] bg-white py-5 md:h-[72px] md:p-0">
        <div className="container relative flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="pr-3">
              <img
                src={IMAGES.global.logo}
                id="logoMobile"
                className={'h-[25px] cursor-pointer'}
                alt=""
              />
            </Link>
            <div className="flex !hidden h-[25px] items-center gap-2 border-l border-[#DEDEDE] pl-3">
              <Link to="https://t.me/GhoflyGames/">
                <IconTelegram />
              </Link>
              <Link to="https://www.instagram.com/ghoflygames/">
                <IconInstagram />
              </Link>
            </div>
          </div>
          <div className="hidden lg:block">
            <ul className="flex gap-10">
              <li className="flex w-full justify-center text-center font-medium">
                <Link
                  className={({ isActive }) =>
                    isActive ? 'text-black' : 'text-[#333]'
                  }
                  to="/"
                  end
                >
                  Home
                </Link>
              </li>
              <li className="flex w-full justify-center text-center font-medium">
                <Link
                  to="/games"
                  className={({ isActive }) =>
                    isActive ? 'text-black' : 'text-[#333]'
                  }
                  end
                >
                  Games
                </Link>
              </li>
              <li className="flex w-full justify-center text-center font-medium">
                <Link
                  end
                  to="/store"
                  className={({ isActive }) =>
                    isActive ? 'text-black' : 'text-[#333]'
                  }
                >
                  Store
                </Link>
              </li>
              <li className="flex w-full justify-center text-center font-medium">
                <Link
                  className={({ isActive }) =>
                    isActive ? 'text-black' : 'text-[#333]'
                  }
                  end
                  to="/leaderboard"
                >
                  Leaderboard
                </Link>
              </li>
            </ul>
          </div>
          <div
            id="desktopMenu"
            className="hidden h-[40px] items-center lg:flex"
          >
            {!userAuth && (
              <div>
                <button
                  className="h-[25px] w-[104px] text-[16px] font-medium focus:outline-none"
                  onClick={() => {
                    setIsShowModalSignin(true);
                    htmlTag.classList.add('overflow-y-hidden');
                  }}
                >
                  Login
                </button>
                <button
                  className="h-[40px] w-[104px] rounded-lg bg-[#5142FF] font-semibold text-white focus:outline-none"
                  onClick={() => {
                    setIsShowModalSignup(true);
                    htmlTag.classList.add('overflow-y-hidden');
                  }}
                >
                  Signup
                </button>
              </div>
            )}
            {userAuth && (
              <>
                <div className="px-4 border-r flex flex-col items-end gap-1">
                  <div className="flex gap-1 items-center">
                    <button onClick={showModalTrasferGemHandler} className="group border p-0.5 border-[#9450E0] rounded-[4px] transition-all duration-300 hover:bg-[#9450E0] hidden">
                      <IconTransfer />
                    </button>
                    <div className="flex items-center gap-1">
                      <img
                        src={IMAGES.website.global.gemIcon}
                        width={20}
                        height={20}
                        alt="point icon"
                      />
                      <p className="text-[14.5px] font-semibold text-[#9450E0]">
                        {userAuth.gems} Gems
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <img
                      src={IMAGES.website.global.pointIcon}
                      width={20}
                      height={20}
                      alt="point icon"
                    />
                    <p className="text-[14.5px] font-semibold text-[#c3912c]">
                      {userAuth.points} Points
                    </p>
                  </div>
                </div>
                <div className="z-10 flex h-10 items-center justify-center gap-2 py-2 text-[16px] font-bold pl-4">
                  <div
                    className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-[#FF7658] text-[19px] font-semibold text-white"
                    onClick={() => {
                      setIsShowModalDesktopUserModal(true);
                    }}
                  >
                    {userAuth.username.substring(0, 1).toUpperCase()}
                  </div>
                  <div
                    className={`cursor-pointer transition-all duration-300 ${
                      isShowModalDesktopUserModal ? 'rotate-180' : 'rotate-0'
                    }`}
                    onClick={() => {
                      setIsShowModalDesktopUserModal(true);
                    }}
                  >
                    <IconChevronBottom />
                  </div>
                  <div>
                    <div
                      className={`fixed bottom-0 left-0 right-0 top-0 h-screen ${
                        isShowModalDesktopUserModal
                          ? 'visible'
                          : 'invisible opacity-0'
                      }`}
                      onClick={() => setIsShowModalDesktopUserModal(false)}
                    />
                    <div
                      className={`transition-all duration-300 ${
                        isShowModalDesktopUserModal
                          ? 'visible opacity-100'
                          : 'invisible opacity-0'
                      }`}
                    >
                      <div className="absolute right-3 top-14 z-50 mt-2 min-w-[230px] rounded-2xl border border-[#e4e3e3] bg-white p-2">
                        <div className="px-3 pb-2 pt-3">
                          <div className="flex items-center justify-between gap-8">
                            <div>
                              <p className="text-[15px] font-medium">
                                {userAuth.username}
                              </p>
                              <p className="mt-2 text-[15px] font-normal text-[#B6B6B6]">
                                {userAuth.email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <ul className="text-[15px] font-medium">
                          <li className="flex h-[30px] items-center rounded-md pl-2 transition-all duration-300 hover:bg-[#F9F9F9]">
                            <Link
                              to={'/user-profile/'}
                              className="w-full"
                              onClick={() =>
                                setIsShowModalDesktopUserModal(false)
                              }
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="flex h-[30px] items-center rounded-md pl-2 transition-all duration-300 hover:bg-[#F9F9F9]">
                            <Link
                              to={'/user-change-password/'}
                              className="w-full"
                              onClick={() =>
                                setIsShowModalDesktopUserModal(false)
                              }
                            >
                              Change Password
                            </Link>
                          </li>
                        </ul>
                        <button
                          className="flex h-[30px] w-full items-center rounded-md pl-2 text-[15px] font-medium text-[#7E7E7E] transition-all duration-300 hover:bg-[#F9F9F9]"
                          onClick={() => {
                            setIsShowMenuMobile(false);
                            localStorage.removeItem('userAuth');
                            localStorage.removeItem(
                              'questionPackAnswredNumber',
                            );
                            setAuth('');
                            window.location.reload(false);
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="lg:hidden flex">
            {userAuth && (
              <div className="px-4 border-r flex flex-col items-end gap-1">
                <div className="flex gap-1 items-center">
                  <button onClick={showModalTrasferGemHandler} className="group border p-0.5 border-[#9450E0] rounded-[4px] transition-all duration-300 hover:bg-[#9450E0] hidden">
                    <IconTransfer />
                  </button>
                  <div className="flex items-center gap-1">
                    <img
                      src={IMAGES.website.global.gemIcon}
                      width={20}
                      height={20}
                      alt="point icon"
                    />
                    <p className="text-[14.5px] font-semibold text-[#9450E0]">
                      {userAuth.gems} Gems
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <img
                    src={IMAGES.website.global.pointIcon}
                    width={20}
                    height={20}
                    alt="point icon"
                  />
                  <p className="text-[14.5px] font-semibold text-[#c3912c]">
                    {userAuth.points} Points
                  </p>
                </div>
              </div>
            )}
            <img
              className="pl-4"
              id="mobileMenuButton"
              src={IMAGES.website.template.header.mobileMenuShowBtn}
              alt=""
              onClick={() => setIsShowMenuMobile(true)}
            />
          </div>
        </div>
        <div className={`overflow-hidden`}>
          <div
            className={`absolute bottom-0 left-0 right-0 top-0 h-full bg-white px-6 sm:px-[64px] md:px-8 ${
              isShowMenuMobile ? 'visible' : 'invisible'
            }`}
          >
            <div>
              <div className="mt-5 flex justify-between">
                <Link to="/">
                  <img
                    src={IMAGES.global.logo}
                    className={'h-[25px] cursor-pointer'}
                    onClick={() => {
                      setIsShowMenuMobile(false);
                    }}
                    alt=""
                  />
                </Link>
                <button onClick={() => setIsShowModalSignup(false)}>
                  <img
                    src={IMAGES.website.template.header.mobileMenuCloseBtn}
                    className="mr-1"
                    alt=""
                    onClick={() => setIsShowMenuMobile(false)}
                  />
                </button>
              </div>
              {userAuth ? (
                <p className="mt-8 text-[20px] font-medium text-center">
                  {userAuth.username}
                </p>
              ) : (
                <div
                  className={`mt-10 w-full flex justify-center ${
                    isShowMenuMobile ? 'visible' : 'hidden'
                  }`}
                >
                  <div className="w-full max-w-[330px] flex gap-4">
                    <div className="w-1/2">
                      <RippleBtn className="w-full">
                        <button
                          className="w-full rounded-lg border border-[#5142FF] py-2.5 font-semibold text-[#5142ff] transition-all duration-500 hover:bg-[#5143ea] hover:text-white"
                          onClick={() => {
                            setIsShowModalSignin(true);
                            htmlTag.classList.add('overflow-y-hidden');
                          }}
                        >
                          Login
                        </button>
                      </RippleBtn>
                    </div>
                    <div className="w-1/2">
                      <RippleBtn className="w-full">
                        <button
                          className="w-full rounded-lg border border-[#5142ff] bg-[#5142ff] py-2.5 font-semibold text-white transition-all duration-500 hover:bg-[#5143ea]"
                          onClick={() => {
                            setIsShowModalSignup(true);
                            htmlTag.classList.add('overflow-y-hidden');
                          }}
                        >
                          Signup
                        </button>
                      </RippleBtn>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-6 flex justify-center">
                <span className="w-[180px] border border-[#D9D9D9]"></span>
              </div>
              <div className="absolute left-0 right-0">
                <ul
                  id="navLinks"
                  className="mt-4 flex w-full flex-col items-center text-[20px] font-medium"
                >
                  {userAuth && (
                    <>
                      <li className="flex w-full justify-center text-center">
                        <Link
                          onClick={() => setIsShowMenuMobile(false)}
                          to="/user-profile"
                          end
                          className={'py-2.5'}
                        >
                          My Account
                        </Link>
                      </li>
                      <li className="flex w-full justify-center text-center">
                        <Link
                          onClick={() => setIsShowMenuMobile(false)}
                          to="/user-change-password"
                          end
                          className={'py-2.5'}
                        >
                          Change Password
                        </Link>
                      </li>
                    </>
                  )}
                  <li className="flex w-full justify-center text-center">
                    <Link
                      onClick={() => setIsShowMenuMobile(false)}
                      to="/"
                      end
                      className={'py-2.5'}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="flex w-full justify-center text-center">
                    <Link
                      onClick={() => setIsShowMenuMobile(false)}
                      to="/games"
                      end
                      className={'py-2.5'}
                    >
                      Games
                    </Link>
                  </li>
                  <li className="flex w-full justify-center text-center">
                    <Link
                      onClick={() => setIsShowMenuMobile(false)}
                      className={'py-2.5'}
                      end
                      to="/store"
                    >
                      Store
                    </Link>
                  </li>
                  <li className="flex w-full justify-center text-center">
                    <Link
                      onClick={() => setIsShowMenuMobile(false)}
                      className={'py-2.5'}
                      end
                      to="/leaderboard"
                    >
                      Leaderboard
                    </Link>
                  </li>
                  {userAuth && (
                    <button
                      className="flex w-full justify-center py-3 text-center text-[#7E7E7E]"
                      onClick={() => {
                        closeMobileMenuHandler();
                        localStorage.removeItem('userAuth');
                        setAuth('');
                        window.location.reload(false);
                      }}
                    >
                      Logout
                    </button>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>{children}</main>
      <footer className="flex w-full flex-col items-center border-t border-[#ececec] bg-white py-6 text-[15px] font-medium">
        <div className="w-[100px] sm:w-[110px] md:w-[120px]">
          <img src={IMAGES.website.global.ghofly3d} alt="" />
        </div>
        <ul className="flex w-[300px] flex-wrap justify-center gap-5 pt-6 text-[#444] sm:w-full sm:text-[16px]">
          <li className="transition-all duration-500 hover:text-black">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li className="transition-all duration-500 hover:text-black">
            <Link to="/eula">Eula</Link>
          </li>
          <li className="transition-all duration-500 hover:text-black">
            <Link to="/terms">Terms & Conditions</Link>
          </li>
          <li className="transition-all duration-500 hover:text-black">
            <Link to="/contactus">Contact-us</Link>
          </li>
          <li className="transition-all duration-500 hover:text-black">
            <Link to="/aboutus">About-us</Link>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default Layout;
