const AdsenseRight = () => {

  return (
    <div className="rounded-lg h-full font-bold flex items-center justify-center bg-white border w-full py-10">
      ads
    </div>
  );
};

export default AdsenseRight;
