import IMAGES from 'constants/images';
import { useAuth, useAuthAction } from 'providers/AuthProvider';
import {
  useGrantAccessPlaySound,
  useGrantAccessPlaySoundAction,
} from 'providers/GrantAccessPlaySoundProvieder';
import { useEffect, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Oval } from 'react-loader-spinner';
import SecureLS from 'secure-ls';
import http from 'services/httpService';
import WaveSurfer from 'wavesurfer.js';

const GameMusicAnswer = ({
  questions,
  questionToken,
  songInformationHanlder,
  songUrl,
}) => {
  const secureLocalStorage = new SecureLS({ encodingType: 'aes' });
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const userAuth = useAuth();
  const questionAnswersRef = useRef();
  const currectQuestionIndex = useRef(null);
  const setAuth = useAuthAction();

  // for lock btn after clicked
  const [clickedToBtnForGetAnswer, setClickedToBtnForGetAnswer] =
    useState(false);

  const [acceptGrantAccessForPlay, setAcceptNeedGrantAccessForPlay] =
    useState(false);
  const grantAccessPlaySound = useGrantAccessPlaySound();
  const setGrantAccessPlaySound = useGrantAccessPlaySoundAction();

  const [isShowGetSongInformationBtn, setIsShowGetSongInformationBtn] =
    useState(false);
  const [songInformation, setSongInformation] = useState(null);

  const [activedCircle, setActivedCircle] = useState('loader');

  const [currentGameStepPoint, setCurrentGameStepPoint] = useState(null);

  const waveSongSurferOptions = (ref) => ({
    container: ref,
    waveColor: '#aaaaaa',
    progressColor: '#5143EF',
    cursorColor: 'transparent',
    barWidth: 3,
    barRadius: 3,
    barGap: 4,
    interact: false,
    responsive: true,
    height: 50,
    normalize: true,
    partialRender: true,
  });

  useEffect(() => {
    const options = waveSongSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(songUrl);
    wavesurfer.current.on('ready', function () {
      wavesurfer.current.setVolume(1);
      setTimeout(() => {
        setAcceptNeedGrantAccessForPlay(true);
      }, 300);
      if (grantAccessPlaySound) {
        wavesurfer.current.play();
        setActivedCircle('timer');
      }
    });
    return () => wavesurfer.current.destroy();
  }, [songUrl]);

  const showQuestionAnswerHandler = (event, fetchApi) => {
    //! 0 - lock btns
    setClickedToBtnForGetAnswer(true);
    //! 1 - click user to answer-btn or finish time
    //* if timer finish or clicked answer button disable click to all buttons
    questionAnswersRef.current.classList.add('pointer-events-none');
    //* add selected answer to variable for send to /questions/music/check
    let selectedAnswer = event?.target.innerText;
    //* show spiner-loader before get answer result (show failed or success icon after get result)
    setActivedCircle('loader');
    //! 2 - send request to backend for current answer
    //* add border to clicked answer button by user
    if (event) {
      questionAnswersRef.current.children[
        event.target.getAttribute('button-key')
      ].classList.add('!border-[#5143EA]');
    }
    http
      .post(
        `/questions/music/${fetchApi}/`,
        fetchApi === 'check'
          ? { token: questionToken, key: selectedAnswer }
          : { token: questionToken },
        { headers: { Authorization: userAuth.token } },
      )
      .then(({ data }) => {
        if (event) {
          //* remove border from clicked btn
          questionAnswersRef.current.children[
            event.target.getAttribute('button-key')
          ].classList.remove('!border-[#5143EA]');
        }
        //* update user-point from userPoints-property-backend
        if (data.userPoints) {
          const userData = JSON.parse(secureLocalStorage.get('userAuth'));
          userData.points = data.userPoints;
          setAuth(userData);
        }
        //* set current game step point to state for show to user
        setCurrentGameStepPoint(data.thisGamePoint);
        //* select four-answer-button parent
        const answers = questionAnswersRef.current.children;
        //* find currect-answer by key and save to ref
        for (var i = 0; i < answers.length; i++) {
          if (answers[i].outerText === data.key) {
            currectQuestionIndex.current =
              answers[i].getAttribute('button-key');
          }
        }
        //* find currect-answer by key and save to ref
        if (data.message === 'Your answer was not correct!') {
          //* add bg-red-400 and remove-border-added and text-white for not-correct-selected-answer
          questionAnswersRef.current.children[
            event.target.getAttribute('button-key')
          ].classList.add('!text-white', '!bg-red-400');
          //* add bg-green-400 for correct-answer-sended-from-backend
          questionAnswersRef.current.children[
            currectQuestionIndex.current
          ].classList.add('!bg-green-400');
          //* change spiner-loader to failed-icon
          setActivedCircle('failed');
        } else if (data.message === 'Your answer was correct!') {
          //* if selected-button is correct add bg-green-400 and hide border
          questionAnswersRef.current.children[
            event.target.getAttribute('button-key')
          ].classList.add('!bg-green-400');
          //* replace icon success to spiner-loader
          setActivedCircle('success');
        } else if (data.message === 'Your time has been finished!') {
          //* add bg-green-400 for correct-answer-sended-from-backend
          questionAnswersRef.current.children[
            currectQuestionIndex.current
          ].classList.add('!bg-green-400');
          //* change spiner-loader to failed-icon
          setActivedCircle('failed');
        }
        //! 4 - show button with text get-music-information and set music information (conver, track name, artist name)
        setTimeout(() => {
          setSongInformation([
            data?.cover,
            data?.musicName,
            data?.singerName,
            data?.spotifyLink ? data.spotifyLink : null,
            data?.youtubeLink ? data.youtubeLink : null,
            data?.questionNumber,
          ]);
          setIsShowGetSongInformationBtn(true);
          setActivedCircle('point');
        }, 3000);
      });
  };

  return (
    <>
      {/* show modal play icon for click and grant access for play auto music */}
      {acceptGrantAccessForPlay && (
        <div>
          <div
            className={`modalOverlay ${
              !grantAccessPlaySound
                ? 'visible bg-black/30'
                : 'invisible opacity-0'
            }`}
          />
          <div
            className={`modalAnimationShowAndHide ${
              !grantAccessPlaySound
                ? 'scale-1 !z-9999 visible opacity-100'
                : 'invisible !-z-10 scale-75 opacity-0'
            }`}
          >
            <div
              className={`absolute bottom-0 left-0 right-0 top-0 flex w-full items-center justify-center`}
            >
              <div
                className={`relative z-20 flex w-[90%] flex-col items-end justify-between gap-6 rounded-[25px] p-5 transition-all duration-300 md:w-[550px]`}
              >
                <div className="w-full space-y-6 py-4 text-center">
                  <button
                    className=""
                    onClick={() => {
                      wavesurfer.current.play();
                      setActivedCircle('timer');
                      setGrantAccessPlaySound(true);
                    }}
                  >
                    <img
                      src={IMAGES.website.routes.games.song.playMusicButton}
                      width={200}
                      height={200}
                      alt=""
                    />
                    <p className="mt-4 text-[30px] font-bold text-[#744CEB]">
                      Click To Play
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col items-center gap-4">
        {/* circle loader, circle timer, circle success, circle failed */}
        <div className="relative flex h-[60px] w-[60px] items-center justify-center">
          {activedCircle === 'timer' ? (
            <div
              className={`countDownCircleTimer absolute bottom-0 left-0 right-0 top-0 text-[20px] font-black text-[#5142FF]`}
            >
              <CountdownCircleTimer
                isPlaying={true}
                duration={6}
                colors={'#5142FF'}
                size={56}
                strokeWidth={5}
                trailColor={'#d8d8d8'}
                rotation={'counterclockwise'}
                onComplete={() => {
                  showQuestionAnswerHandler(null, 'timeout');
                }}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
          ) : activedCircle === 'loader' ? (
            <div className={'absolute bottom-0 left-0 right-0 top-0'}>
              <Oval
                height={57}
                width={57}
                color="#5142FF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#777"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </div>
          ) : activedCircle === 'success' ? (
            <img
              className={`absolute bottom-0 left-0 right-0 top-0`}
              src={IMAGES.website.routes.games.global.success}
              width={55}
              alt=""
            />
          ) : activedCircle === 'failed' ? (
            <img
              className={`absolute bottom-0 left-0 right-0 top-0`}
              src={IMAGES.website.routes.global.failed}
              width={55}
              alt=""
            />
          ) : activedCircle === 'point' ? (
            <div
              className={`flex h-[55px] w-[55px] items-center justify-center rounded-full border-[5px] text-[20px] font-black ${
                currentGameStepPoint === 0
                  ? 'border-yellow-400 text-yellow-400'
                  : currentGameStepPoint < 0
                  ? 'border-red-400 text-red-400'
                  : currentGameStepPoint > 0
                  ? 'border-green-500 text-green-500'
                  : ''
              }`}
            >
              {Number(currentGameStepPoint) > 0 && '+'}
              {currentGameStepPoint}
            </div>
          ) : null}
        </div>
        <div className="text-center">
          {/* show wave muisc */}
          <div className="h-[50px] w-[250px] text-[18px] font-semibold leading-8 sm:w-[300px] xl:w-[380px]">
            <div id="waveform" ref={waveformRef} />
          </div>
        </div>
        {/* show four-answer-btn */}
        <div
          className={`grid grid-cols-2 gap-4 ${
            (activedCircle === 'loader' || clickedToBtnForGetAnswer) &&
            'pointer-events-none'
          }`}
          ref={questionAnswersRef}
        >
          {questions.map((item, index) => {
            return (
              <button
                button-key={index}
                onClick={(e) => {
                  showQuestionAnswerHandler(e, 'check');
                }}
                className={`h-[100px] w-[140px] rounded-2xl border border-transparent bg-white px-1 text-[16px] font-medium shadow-sm shadow-slate-200 outline-none transition-all duration-300 hover:bg-gray-50 sm:w-[170px] xl:h-[140px] xl:w-[220px]`}
                key={index}
              >
                {item}
              </button>
            );
          })}
        </div>
        {/* show button for click and get music information */}
        {isShowGetSongInformationBtn && (
          <div className="flex w-full justify-center">
            <button
              className={`mt-3 w-[300px] rounded-lg border border-[#5142ff] bg-[#5142ff] py-5 pt-4 text-[18px] font-semibold text-white transition-all duration-500 hover:bg-[#5143ea] md:w-[350px]`}
              onClick={() => {
                songInformationHanlder(songInformation);
              }}
            >
              Get Music Information
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default GameMusicAnswer;
